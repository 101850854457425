import { useEffect, useState } from "react";
import accountBalanceIcon from "../../../../../../common/images/account_balance_icon.png";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { getAccountBalance } from "../../../../services/fundsManagementService";
import StatsBox from "../StatsBox/StatsBox";

export default function AccountBalance() {
  const dispatch = useAppDispatch();
  const stripeBalance = useAppSelector(
    (state) => state.fundsManagement.stripeBalance
  );
  useEffect(() => {
    //dispatch(getAccountBalance());
  }, []);

  const [statValue, setStatValue] = useState<number>(0);

  useEffect(() => {
    let totalBalance = 0;
    if (stripeBalance) {
      stripeBalance.available.reduce((previousBalance, stripeBalance) => {
        return (totalBalance = previousBalance + stripeBalance.amount);
      }, 0);
    }
    setStatValue(totalBalance);
  }, [JSON.stringify(stripeBalance)]);
  return (
    <>
      <StatsBox
        statValue={statValue}
        title="Solde disponible"
        img={{ url: accountBalanceIcon }}
        transferButton={true}
      />
    </>
  );
}
