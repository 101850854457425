import { Grid } from "@mui/material";
import MenuItemBox from "../MenuItemBox/MenuItemBox";
import MenuItemSample from "../MenuItemSample/MenuItemSample";
import CreateMenuItemModal from "../Modals/CreateMenuItemModal";
import { TMenuItemListProps } from "./types/TMenuItemListProps";

export default function MenuItemList(props: TMenuItemListProps) {
  return (
    <>
      <CreateMenuItemModal
        selectedMenu={props.selectedMenu}
        selectedCategory={props.selectedCategory}
      />

      <Grid container sx={{ width: "100%" }} direction={"row"} mt={4}>
        {props.selectedCategory.itemList.map((item) => (
          <MenuItemBox
            item={item}
            disabled={false}
            selectedMenu={props.selectedMenu}
            selectedCategory={props.selectedCategory}
          />
        ))}
        <MenuItemSample />
      </Grid>
    </>
  );
}
