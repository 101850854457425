import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { appActions } from "../../../../common/store/appSlice";
import { useAppDispatch, useAppSelector } from "../../../../common/store/redux";
import BankTransferPage from "../../../Account/modules/FundsManagement/containers/Pages/BankTransferPage";
import MainReviewsPage from "../../../Reviews/containers/Pages/MainReviewsPage";
import DashboardSideBar from "../SideBar/DashboardSideBar";
import DashboardHomePage from "./DashboardHomePage";
import { getAll } from "../../../Reviews/services/reviewsService";
import { getUpdateStripeAccoutLink } from "../../../Account/services/stripeAccountService";
import MainMenuCreationPage from "../../../Menu/modules/menu/containers/Pages/MainMenuCreationPage";
import MenuManagementContent from "../../../Menu/modules/menu/containers/Pages/MenuManagementContent";

export default function MainDashboardPage() {
  const appLoading = useAppSelector((state) => state.app.appLoading);
  const dispatch = useAppDispatch();
  const reviewsReport = useAppSelector((state) => state.reviews.reviewsReport);

  useEffect(() => {
    dispatch(getAll());
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const SIDEBAR_WIDTH = isMobile ? 180 : isTablet ? 180 : 231;

  return (
    <Box sx={{ display: "flex", height: "calc(100vh - 86px)" }}>
      <Box sx={{ height: "100%", flexShrink: 0, width: SIDEBAR_WIDTH }}>
        <DashboardSideBar />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
          overflow: "hidden", // Prevents this box from scrolling
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto", // Allows this inner box to scroll
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Container
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              mt: 5,
              mb: 5,
            }}
          >
            <Routes>
              <Route path="menus/*" element={<MenuManagementContent />} />
              <Route
                path="/"
                element={<DashboardHomePage reviewsReport={reviewsReport} />}
              />
              <Route path="/bank/transfer" element={<BankTransferPage />} />
              <Route
                path="/reviews"
                element={<MainReviewsPage reviewsReport={reviewsReport} />}
              />
            </Routes>
          </Container>
        </Box>
      </Box>
    </Box>
  );
}
