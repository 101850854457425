import { createAsyncThunk } from "@reduxjs/toolkit";
import { MethodEnum } from "../../../common/modules/enums/MethodEnum";
import { apiRequest } from "../../../common/modules/temporary/api";
import { customFormActions } from "../../../common/store/customFormSlice";
import { TOnTransferToBankRequest } from "./types/Requests/TOnTransferToBankRequest";
import { TOnGetAccountBalanceResponse } from "./types/Responses/TOnGetAccountBalanceResponse";
import { TOnTransferToBankResponse } from "./types/Responses/TOnTransferToBankResponse";
import { fundsManagementActions } from "../modules/FundsManagement/slices/fundsManagementSlice";
import { TOnRetrieveTransferListRequest } from "./types/Requests/TOnRetrieveTransferListRequest";
import { TOnRetrieveTransferListResponse } from "./types/Responses/TOnRetrieveTransferListResponse";
import Stripe from "stripe";

export const getAccountBalance = createAsyncThunk(
  "funds-management/account-balance",
  async (_, { dispatch }) => {
    const response = await apiRequest<TOnGetAccountBalanceResponse>(
      dispatch,
      "/api/funds-management/account-balance",
      MethodEnum.GET,
      null
    );
    if (response.success) {
      //Need to check it later again
      console.log("Account balance fetched ", response);
      dispatch(fundsManagementActions.setStripeBalance(response.data.balance));
      //dispatch(accountActions.setUpdateStripeAccountLink(data.data.url));
    }
  }
);
export const transferToBank = createAsyncThunk(
  "funds-management/transfer-to-bank",
  async (request: TOnTransferToBankRequest, { dispatch }) => {
    const response = await apiRequest<TOnGetAccountBalanceResponse>(
      dispatch,
      "/api/funds-management/transfer-to-bank",
      MethodEnum.POST,
      request
    );
    if (response.success) {
      //Need to check it later again
      console.log("Account balance fetched ", response);
      dispatch(fundsManagementActions.setStripeBalance(response.data.balance));
      //dispatch(accountActions.setUpdateStripeAccountLink(data.data.url));
    }
  }
);
export const getTransferList = createAsyncThunk(
  "funds-management/transfer/find-all",
  async (request: TOnRetrieveTransferListRequest, { dispatch }) => {
    const response = await apiRequest<TOnRetrieveTransferListResponse>(
      dispatch,
      `/api/funds-management/transfer/find-all/${request.limit}`,
      MethodEnum.GET
    );
    if (response.success) {
      console.log("transferList", response.data.payouts);
      const samplePayouts: Stripe.ApiList<Stripe.Payout> = {
        object: "list",
        data: [
          {
            id: "po_1OaFDbEcg9tTZuTgNYmX0PKB",
            object: "payout",
            amount: 1100,
            arrival_date: 1680652800,
            automatic: true,
            balance_transaction: "txn_1IM9Es2eZvKYlo2CgC23ZSxH",
            created: 1680649200,
            currency: "usd",
            description: "Sample Payout 1",
            destination: "ba_1MtIhL2eZvKYlo2CAElKwKu2",
            failure_balance_transaction: null,
            failure_code: null,
            failure_message: null,
            livemode: false,
            metadata: {},
            method: "standard",
            original_payout: null,
            reconciliation_status: "completed",
            source_type: "card",
            statement_descriptor: null,
            status: "paid",
            type: "bank_account",
            application_fee: null,
            application_fee_amount: null,
            reversed_by: null,
          },
          {
            id: "po_1OaFGHEcg9tTZuTgT3bF1234",
            object: "payout",
            amount: 2500,
            arrival_date: 1680752800,
            automatic: true,
            balance_transaction: "txn_1IM9Fs2eZvKYlo2CgC23ZSxJ",
            created: 1680751200,
            currency: "usd",
            description: "Sample Payout 2",
            destination: "ba_1NtJkE2eZvKYlo2CAElKwKu2",
            failure_balance_transaction: null,
            failure_code: null,
            failure_message: null,
            livemode: false,
            metadata: {},
            method: "standard",
            original_payout: null,
            reconciliation_status: "completed",
            source_type: "card",
            statement_descriptor: null,
            status: "paid",
            type: "bank_account",
            application_fee: null,
            application_fee_amount: null,
            reversed_by: null,
          },
          {
            id: "po_1OaFJKEcg9tTZuTgW12345k4",
            object: "payout",
            amount: 3000,
            arrival_date: 1680852800,
            automatic: true,
            balance_transaction: "txn_1IM9Gs2eZvKYlo2CgC23ZSxK",
            created: 1680853200,
            currency: "usd",
            description: "Sample Payout 3",
            destination: "ba_1PtLiE2eZvKYlo2CAElKwKu2",
            failure_balance_transaction: null,
            failure_code: "insufficient_funds",
            failure_message: "Insufficient funds",
            livemode: false,
            metadata: {},
            method: "standard",
            original_payout: null,
            reconciliation_status: "completed",
            source_type: "card",
            statement_descriptor: null,
            status: "paid",
            type: "bank_account",
            application_fee: null,
            application_fee_amount: null,
            reversed_by: null,
          },
        ],
        has_more: false,
        url: "/v1/payouts",
      };
      dispatch(fundsManagementActions.setTransferList(samplePayouts));
    }
  }
);
