import { createAsyncThunk } from "@reduxjs/toolkit";
import { MethodEnum } from "../../../common/modules/enums/MethodEnum";
import { apiRequest } from "../../../common/modules/temporary/api";
import { customFormActions } from "../../../common/store/customFormSlice";
import { authActions } from "../slices/authSlice";

import { TOnLogoutRequest } from "./types/Requests/TOnLogoutRequest";
import { TOnRefreshUserByTokenRequest } from "./types/Requests/TOnRefreshUserByTokenRequest";
import { TOnSignInRequest } from "./types/Requests/TOnSignInRequest";
import { TOnSignUpRequest } from "./types/Requests/TOnSignUpRequest";
import { TOnRefreshUserByTokenResponse } from "./types/Responses/TOnRefreshUserByTokenResponse";
import { TOnSignInResponse } from "./types/Responses/TOnSignInResponse";
import { TOnSignupResponse } from "./types/Responses/TOnSignUpResponse";
import {
  clearAccessToken,
  clearRefreshToken,
  clearTokens,
  retrieveAccessToken,
  storeAccessToken,
  storeRefreshToken,
} from "../../../utils/crypto";
import { createMenu } from "../../Menu/modules/menu/service/menusServices";

export const signIn = createAsyncThunk(
  "user/signin",
  async (request: TOnSignInRequest, { dispatch }) => {
    const response = await apiRequest<TOnSignInResponse>(
      dispatch,
      "/api/user/signin",
      MethodEnum.POST,
      request
    );

    if (response.success) {
      if (response.data.authToken.refreshToken) {
        clearRefreshToken();
        storeRefreshToken(response.data.authToken.refreshToken);
      }
      clearAccessToken();
      storeAccessToken(response.data.authToken.accessToken);
      console.log("accessToken", retrieveAccessToken());
      dispatch(authActions.signInSuccess(response.data));
    } else {
      if (response.error.type === "authentication.failed") {
        dispatch(
          customFormActions.setFormError({
            name: "sign-in-form",
            message: "Email ou mot de passe incorrect",
          })
        );
      }
    }
  }
);
export const signUp = createAsyncThunk(
  "user/signup",
  async (request: TOnSignUpRequest, { dispatch }) => {
    const response = await apiRequest<TOnSignupResponse>(
      dispatch,
      "/api/user/signup",
      MethodEnum.POST,
      request
    );
    if (response.success) {
      //Clear token if there was any
      clearTokens();
      storeAccessToken(response.data.accessToken);
      dispatch(authActions.signUpSuccess(response.data));
      dispatch(customFormActions.setFormSuccess("sign-up-form"));
      dispatch(
        createMenu({
          name: "Menu par défaut",
          activeFrom: null,
          activeTo: null,
          type: "DEFAULT",
        })
      );
    } else {
      if (response.error.type === "authentication.email_already_exists") {
        dispatch(
          customFormActions.setFormError({
            name: "sign-up-form",
            message: "Adresse email déjà utilisée",
          })
        );
      }
    }
  }
);

export const refreshUser = createAsyncThunk(
  "user/refresh",
  async (request: TOnRefreshUserByTokenRequest, { dispatch }) => {
    const response = await apiRequest<TOnRefreshUserByTokenResponse>(
      dispatch,
      "/api/user/refresh",
      MethodEnum.POST,
      request
    );
    if (response.success) {
      if (response.data.authToken.refreshToken) {
        clearRefreshToken();
        storeRefreshToken(response.data.authToken.refreshToken);
      }
      clearAccessToken();
      storeAccessToken(response.data.authToken.accessToken);
      dispatch(authActions.refreshUserSuccess(response.data.user));
    } else {
      clearTokens();
      dispatch(authActions.refreshUserFailure());
    }
  }
);

export const signOut = createAsyncThunk(
  "user/logout",
  async (request: TOnLogoutRequest, { dispatch }) => {
    const data = await apiRequest(
      dispatch,
      "/api/user/logout",
      MethodEnum.POST,
      request
    );
    if (data.success) {
      dispatch(authActions.signOutSuccess());
    }
    clearTokens();
  }
);
