import { Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { menuActions } from "../../slice/menuSlice";
import { getMenu } from "../../service/menusServices";
import { categoryActions } from "../../../category/slice/categorySlice";
import MenuCategoryNavigationBar from "../../../category/containers/MenuCategoryNavigationBar/MenuCategoryNavigationBar";
import MenuItemList from "../../../item/container/MenuItemList/MenuItemList";
import { IMenuEntity } from "../../../../../../common/Entities/IMenuEntity";
import { TSelectMenuPageProps } from "./types/TSelectMenuPageProps";
import { IMenuCategoryEntity } from "../../../../../../common/Entities/IMenuCategoryEntity";
import { se } from "date-fns/locale";
import { itemActions } from "../../../item/slice/itemSlice";

export default function SelectedMenuPage(props: TSelectMenuPageProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { menuId } = useParams();

  const [selectedMenu, setSelectedMenu] = useState<IMenuEntity | null>(null);
  const [selectedCategory, setSelectedCategory] =
    useState<IMenuCategoryEntity | null>(null);

  const selectedCategoryId = useAppSelector(
    (state) => state.category.selectedCategory
  )?.id!;

  useEffect(() => {
    if (!menuId) {
      navigate("/dashboard/menus");
    } else {
      const foundMenu = props.menuList.find(
        (menu) => menu.id === Number(menuId)
      );
      console.log("ici ma geule");
      if (!foundMenu) {
        navigate("/dashboard/menus");
      } else {
        setSelectedMenu(foundMenu);
        setSelectedCategory(
          foundMenu.categories.find(
            (category) => category.id === selectedCategoryId
          ) ?? null
        );
      }
    }
  }, [menuId, JSON.stringify(props.menuList)]);

  useEffect(() => {
    if (selectedMenu && selectedCategoryId) {
      const foundCategory = selectedMenu.categories.find(
        (category) => category.id === selectedCategoryId
      );
      if (foundCategory) {
        setSelectedCategory(foundCategory);
      }
    }
  }, [selectedMenu, selectedCategoryId]);

  const handleOpenCreateMenuItemModal = () => {
    dispatch(itemActions.setIsCreateMenuItemModalOpen(true));
  };

  return (
    selectedMenu && (
      <>
        <Grid container pb={10}>
          <MenuCategoryNavigationBar selectedMenu={selectedMenu} />
          {selectedCategory && selectedCategory.itemList.length > 0 && (
            <Grid item mt={2}>
              <Button
                onClick={handleOpenCreateMenuItemModal}
                className="popup-button-blue"
              >
                Ajouter un article
              </Button>
            </Grid>
          )}
          {selectedCategory && (
            <MenuItemList
              selectedCategory={selectedCategory}
              selectedMenu={selectedMenu}
            />
          )}
        </Grid>
      </>
    )
  );
}
