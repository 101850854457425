import { IMenuEntity } from "../../../../../common/Entities/IMenuEntity";
import { RootState } from "../../../../../common/store/store";

// Utility function to deserialize dates
const deserializeDate = (date: string | null): Date | null => {
  return date ? new Date(date) : null;
};

export const selectMenuList = (state: RootState): IMenuEntity[] => {
  return state.menu.menuList.map((menu) => ({
    ...menu,
    activeFrom: deserializeDate(menu.activeFrom),
    activeTo: deserializeDate(menu.activeTo),
  }));
};

export const selectSelectedMenu = (state: RootState): IMenuEntity | null => {
  const selectedMenu = state.menu.selectedMenu;
  return selectedMenu
    ? {
        ...selectedMenu,
        activeFrom: deserializeDate(selectedMenu.activeFrom),
        activeTo: deserializeDate(selectedMenu.activeTo),
      }
    : null;
};
