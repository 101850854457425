import { createAsyncThunk } from "@reduxjs/toolkit";
import { IMenuEntity } from "../../../../../common/Entities/IMenuEntity";
import { MethodEnum } from "../../../../../common/modules/enums/MethodEnum";
import { apiRequest } from "../../../../../common/modules/temporary/api";
import { menuActions } from "../slice/menuSlice";
import { TCreateMenuRequest } from "./Requests/TCreateMenuRequest";
import { TDeleteMenuRequest } from "./Requests/TDeleteMenuRequest";
import { TGetMenuRequest } from "./Requests/TGetMenuRequest";
import { TUpdateMenuRequest } from "./Requests/TUpdateMenuRequest";
import { TCreateMenuResponse } from "./Responses/TCreateMenuResponse";
import { TDeleteMenuResponse } from "./Responses/TDeleteMenuResponse";
import { TFindAvailableMenuResponse } from "./Responses/TFindAvailableMenuRespons";
import { TGetAllMenusResponse } from "./Responses/TGetAllMenusResponse";
import { TGetMenuResponse } from "./Responses/TGetMenuResponse";
import { TUpdateMenuResponse } from "./Responses/TUpdateMenuResponse";

export const findAllMenus = createAsyncThunk(
  "menu/find-all",
  async (_, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TGetAllMenusResponse>(
      dispatch,
      "/api/menu/find-all",
      MethodEnum.GET,
      null
    );
    if (response.success) {
      dispatch(menuActions.setMenuList(response.data.menus));
      dispatch(menuActions.setSelectedMenu(response.data.menus[0]));
    } else {
      return rejectWithValue(response);
    }
  }
);
export const getMenu = createAsyncThunk(
  "menu/find",
  async (request: TGetMenuRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TGetMenuResponse>(
      dispatch,
      `/api/menu/find/${request.menuId}`,
      MethodEnum.GET,
      null
    );
    if (response.success) {
    } else {
      return rejectWithValue(response);
    }
  }
);
export const findAvailableMenu = createAsyncThunk(
  "menu/find-available",
  async (_, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TFindAvailableMenuResponse>(
      dispatch,
      `/api/menu/find-available`,
      MethodEnum.GET,
      null
    );
    if (response.success) {
      return response.data.menus;
    } else {
      return rejectWithValue(response);
    }
  }
);
export const deleteMenu = createAsyncThunk(
  "menu/delete",
  async (request: TDeleteMenuRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TDeleteMenuResponse>(
      dispatch,
      `/api/menu/delete `,
      MethodEnum.DELETE,
      request
    );
    if (response.success) {
      dispatch(menuActions.deleteMenu(request.menu.id!));
      return true;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const updateMenu = createAsyncThunk(
  "menu/update",
  async (request: TUpdateMenuRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TUpdateMenuResponse>(
      dispatch,
      `/api/menu/update`,
      MethodEnum.PUT,
      request
    );
    if (response.success) {
      dispatch(menuActions.updateMenu(request.menu));
      return response.data.menu;
    } else {
      return rejectWithValue(response);
    }
  }
); /*
export const onUpdateShowByDefault = createAsyncThunk(
  "menu/update-showbydefault",
  async (request: TOnUpdateShowByDefaultMenuRequest, { dispatch }) => {
    const response = await apiRequest<null>(
      dispatch,
      `/api/menu/update-showbydefault`,
      MethodEnum.PUT,
      request
    );

    if (response.success) {
      dispatch(menuActions.updateMenu(response.data.menu));
    }
  }
);
export const onUpdateTimeframe = createAsyncThunk(
  "menu/update-timeframe",
  async (request: TOnUpdateMenuTimeframeRequest, { dispatch }) => {
    const response = await apiRequest<null>(
      dispatch,
      `/api/menu/update-timeframe`,
      MethodEnum.PUT,
      request
    );

    if (response.success) {
      dispatch(menuActions.updateMenu(response.data.menu));
    }
  }
);*/

export const createMenu = createAsyncThunk(
  "menu/create",
  async (request: TCreateMenuRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TCreateMenuResponse>(
      dispatch,
      `/api/menu/create`,
      MethodEnum.POST,
      request
    );
    if (response.success) {
      dispatch(menuActions.addMenu(response.data.menu));
      dispatch(menuActions.setSelectedMenu(response.data.menu));
      dispatch(menuActions.setIsCreateMenuModalOpen(false));
      return response.data.menu;
    } else {
      return rejectWithValue(response);
    }
  }
);
