import { Grid } from "@mui/material";
import "../../../../../../common/containers/styles/customFonts.css";
import MenuItemBox from "../../../item/container/MenuItemBox/MenuItemBox";
import { TMenuItemListPreviewProps } from "./types/TMenuItemListPreviewProps";

export default function MenuItemListPreview(props: TMenuItemListPreviewProps) {
  const itemsToShow =
    props.itemList.length > 4 ? props.itemList.slice(0, 4) : props.itemList;

  return (
    <div>
      <Grid
        container
        direction={"row"}
        justifyContent={`${props.itemList.length > 4 && "center"}`}
      >
        {itemsToShow.map((item, index) => {
          return (
            <div key={index}>
              <MenuItemBox
                disabled={true}
                item={item}
                selectedMenu={props.selectedMenu}
                selectedCategory={props.selectedCategory}
              />
            </div>
          );
        })}
      </Grid>
    </div>
  );
}
