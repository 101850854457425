import { createAsyncThunk } from "@reduxjs/toolkit";
import { MethodEnum } from "../../../common/modules/enums/MethodEnum";
import { TInitiatePaymentAttributes } from "./types/TInitiatePaymentAttributes";
import { customFormActions } from "../../../common/store/customFormSlice";
import { paymentActions } from "../slices/paymentSlice";
import { apiRequest } from "../../../common/modules/temporary/api";
import { TOnInitiatePaymentResponse } from "./types/Responses/TOnInitiatePaymentRespons";
import { TOnFindAllPaymentsByUserRefAndDateResponse } from "./types/Responses/TOnFindAllPaymentsByUserRefAndDateResponse";
import { TOnFindAllPaymentsByUserRefAndDateRequest } from "./types/Requests/TOnFindAllPaymentsByUserRefAndDateRequest";
import { TOnInitiatePaymentRequest } from "./types/Requests/TOnInitiatePaymentRequest";

export const initiatePayment = createAsyncThunk(
  "payment/initiate-payment",
  async (request: TOnInitiatePaymentRequest, { dispatch }) => {
    const response = await apiRequest<TOnInitiatePaymentResponse>(
      dispatch,
      "/api/payment/initiate-payment",
      MethodEnum.POST,
      request,
      false
    );
    console.log("inititatePayment request", request);
    console.log("inititatePayment response", response);
    if (response.success) {
      dispatch(paymentActions.setPaymentRef(response.data.client_secret!));
      dispatch(customFormActions.setFormSuccess("stripe-checkout-form"));
    }
  }
);

export const onFindAllByUserRefAndDate = createAsyncThunk(
  "payment/find-allByUserRefAndDate",
  async (request: TOnFindAllPaymentsByUserRefAndDateRequest, { dispatch }) => {
    const response =
      await apiRequest<TOnFindAllPaymentsByUserRefAndDateResponse>(
        dispatch,
        `api/payment/find-allByUserRefAndDate/${request.startDate}/${request.endDate}`,
        MethodEnum.GET,
        request,
        false
      );
    if (response.success) {
      dispatch(paymentActions.setPayments(response.data.payments));
    }
  }
);
