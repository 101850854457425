import { MoreVertOutlined } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import LinesEllipsis from "react-lines-ellipsis";
import { ReactComponent as PersonalizeIcon } from "../../../../../../common/images/menu_item_personalize_icon.svg";
import MenuItemImageUploadBox from "../../../menu/containers/MenuItemImageUploadBox/MenuItemImageUploadBox";
import MoreItemVerticalMenu from "../MoreItemVerticalMenu/MoreItemVerticalMenu";
import { TMenuItemBoxProps } from "./types/TMenuItemBoxProps";
import { useNavigate } from "react-router-dom";
import DeleteMenuItemConfirmationModal from "../Modals/DeleteMenuItemConfirmationModal";

export default function MenuItemBox(props: TMenuItemBoxProps) {
  const navigate = useNavigate();
  const handlePersonalizeMenuItem = () => {
    if (!props.disabled) {
      navigate(
        `/dashboard/menus/${props.selectedMenu!.id!}/category/${props.selectedCategory!.id!}/item/${props.item.id!}`
      );
    }
  };
  return (
    <>
      <DeleteMenuItemConfirmationModal
        selectedItem={props.item}
        selectedCategory={props.selectedCategory}
        selectedMenu={props.selectedMenu}
      />
      <Grid
        item
        sx={{
          backgroundColor: "white",
          borderRadius: "6px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          width: "244px",
          height: "300px",
          margin: "18px",
          marginBottom: "27px",
          marginTop: "27px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          opacity: props.item.inactiveToday ? 0.7 : 1,
        }}
      >
        {props.item.inactiveToday && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(128, 128, 128, 0.3)",
              borderRadius: "6px",
              zIndex: 1,
            }}
          />
        )}
        <Grid
          container
          sx={{
            borderBottom: "1px solid",
            borderColor: "rgba(0, 0, 0, 0.13)",
          }}
          pl={1.5}
          pr={1.5}
          alignContent={"center"}
        >
          <Grid item pt={1} pb={1} xs={10} sm={10} md={10} lg={10} xl={10}>
            <Typography
              sx={{
                fontWeight: "600",
                "&::first-letter": {
                  textTransform: "uppercase",
                },
                fontSize: "16px",
              }}
              className="font-quicksand"
            >
              <LinesEllipsis
                text={props.item.name}
                maxLine={2}
                ellipsis="..."
              />
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent={"end"}
            pt={1}
            pb={1}
            xs={2}
            sm={2}
            md={2}
            lg={2}
            xl={2}
          >
            {props.disabled ? (
              <MoreVertOutlined />
            ) : (
              <Grid item sx={{ zIndex: 100 }}>
                <MoreItemVerticalMenu
                  selectedCategory={props.selectedCategory!}
                  selectedItem={props.item}
                  selectedMenu={props.selectedMenu!}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container mt={2} justifyContent={"center"}>
          <Grid
            item
            sx={{ width: "218px", height: "120px", borderRadius: "10px" }}
          >
            <MenuItemImageUploadBox selectedMenuItem={props.item} />
          </Grid>
        </Grid>
        <Grid item pl={2} pr={2} mt={2}>
          <Typography
            className="listItem-heading-medium-secondary"
            sx={{
              fontSize: "12px",
              "&::first-letter": {
                textTransform: "uppercase",
              },
            }}
          >
            <LinesEllipsis
              text={props.item.description}
              maxLine={2}
              ellipsis="..."
            />
          </Typography>

          <Typography
            className="listItem-heading-medium-secondary"
            sx={{ fontSize: "12px" }}
          >
            {props.item.price} €
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent={"end"}
          pl={2}
          pr={2}
          sx={{ position: "absolute", bottom: "8px", left: 0, right: 0 }}
        >
          <Grid
            item
            sx={{
              cursor: `${!props.disabled && "pointer"}`,
              zIndex: 100,
            }}
            onClick={() => {
              !props.disabled && handlePersonalizeMenuItem();
            }}
          >
            <PersonalizeIcon />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
