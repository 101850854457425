import { Grid, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import logo from "../../images/logo.png";
import AppMobileHeader from "./AppMobileHeader";
import { useAppSelector } from "../../store/redux";
import DashboardHeader from "../../../modules/Dashboard/containers/Header/DashboardHeader";

export default function AppHeader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  return (
    <Grid
      container
      height="86px"
      width="100%"
      alignItems={"center"}
      padding={"25px"}
      sx={{
        backgroundColor: "white",
        top: "0",
        borderBottom: "1px solid",
        borderColor: "rgba(0, 0, 0, 0.13)",
        color: "#091E42",
        fontSize: "16px",
      }}
    >
      <Grid
        item
        xs={2}
        sm={2}
        md={2}
        lg={2}
        xl={2}
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          cursor: "pointer",
        }}
        onClick={() => {
          window.location.href = `${isAuthenticated ? "/dashboard" : "/"}`;
        }}
      >
        <img src={logo} alt="logo" style={{ height: "100%", width: "auto" }} />
      </Grid>
      <Grid
        item
        container
        justifyContent={"end"}
        xs={10}
        sm={10}
        md={10}
        lg={10}
        xl={10}
        sx={{
          display: isMobile ? "none" : "flex",
        }}
      >
        {!isAuthenticated ? (
          <>
            <Grid item pl={2} pr={2}>
              <Typography>
                <Link href="/" color="inherit" underline="none">
                  Accueil
                </Link>
              </Typography>
            </Grid>
            <Grid item pl={2} pr={2}>
              <Typography>
                <Link href="/auth/signin" color="inherit" underline="none">
                  Connexion
                </Link>
              </Typography>
            </Grid>
            <Grid item pl={2} pr={2}>
              <Typography>
                <Link href="/auth/signup" color="inherit" underline="none">
                  Inscription
                </Link>
              </Typography>
            </Grid>
            <Grid
              item
              xs={10}
              sm={10}
              md={10}
              lg={10}
              xl={10}
              sx={{
                display: isMobile ? "flex" : "none",
                justifyContent: "end",
              }}
            >
              <AppMobileHeader />
            </Grid>
          </>
        ) : (
          <>
            <DashboardHeader />
          </>
        )}
      </Grid>
    </Grid>
  );
}
