import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import { TCustomEmailFieldProps } from "./types/TCustomEmailFieldProps";

// Email regex pattern
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export default function CustomEmailField(props: TCustomEmailFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      rules={{
        required: "Veuillez renseigner une adresse email",
        pattern: {
          value: EMAIL_REGEX,
          message: "Veuillez renseigner une adresse email valide",
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          label={props.label}
          value={field.value || ""}
          variant="outlined"
          type="email"
          error={!!error}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      )}
    />
  );
}
