import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { appActions } from "../../store/appSlice";
import { MethodEnum } from "../enums/MethodEnum";
import {
  TApiResponse,
  TApiSuccessResponse,
  TApiErrorResponse,
} from "./types/TApiResponse";
import { retrieveAccessToken } from "../../../utils/crypto";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000, // Cache for 15 minutes
});

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const apiRequest = async <T>(
  dispatch: any,
  url: string,
  methodEnum: MethodEnum,
  body: any = null,
  multipart: boolean = false,
  menuAccessHeader?: string
): Promise<TApiResponse<T>> => {
  try {
    dispatch(appActions.setAppLoading(true));
    dispatch(appActions.setCustomerMenuLoading(true));
    const accessToken = retrieveAccessToken();

    let headers: any = {};

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
      if (!multipart) headers["Content-Type"] = "application/json";
    }

    if (menuAccessHeader) {
      headers.Authorization = menuAccessHeader;
      if (!multipart) headers["Content-Type"] = "application/json";
    }

    const axiosConfig = {
      method: methodEnum,
      url,
      headers,
      data: body,
    };

    if (multipart) {
      let formData = new FormData();
      for (const key in body) {
        if (body[key] instanceof File) {
          formData.append(key, body[key]);
        } else {
          formData.append(key, JSON.stringify(body[key]));
        }
      }
      axiosConfig.data = formData;
      axiosConfig.headers = {
        ...headers,
        "Content-Type": "multipart/form-data",
      };
    } else {
      axiosConfig.data = body;
    }

    const response = await axiosInstance(axiosConfig);
    const data: TApiSuccessResponse<T> = response.data;

    dispatch(appActions.setAppLoading(false));
    dispatch(appActions.setCustomerMenuLoading(false));

    console.log(
      `URL: ${url}, body: ${JSON.stringify(body)}, data: ${JSON.stringify(data.data)}, message: ${data.message}`
    );
    return data;
  } catch (err: any) {
    dispatch(appActions.setAppLoading(false));
    dispatch(appActions.setCustomerMenuLoading(false));

    if (err.response && err.response.data) {
      const errorData: TApiErrorResponse = err.response.data;
      console.log(
        `URL: ${url}, body: ${JSON.stringify(body)}, error: ${JSON.stringify(errorData.error)}`
      );
      return errorData;
    } else {
      // If the error doesn't have a response (e.g., network error), create a generic error response
      const genericError: TApiErrorResponse = {
        success: false,
        error: {
          type: "server.internal_error",
          message: "An unexpected error occurred. Please try again later.",
        },
      };
      console.log(
        `URL: ${url}, body: ${JSON.stringify(body)}, error: ${JSON.stringify(genericError.error)}`
      );
      return genericError;
    }
  }
};
