import { Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { cartActions } from "../../../../slices/cartSlice";
import { TCartCategoryCheckBoxProps } from "./types/TCartCategoryCheckBoxProps";

export default function CartCategoryCheckBox(
  props: TCartCategoryCheckBoxProps
) {
  const dispatch = useAppDispatch();

  const [checked, setChecked] = useState(false);
  const handleOnClick = () => {
    checked
      ? dispatch(
          cartActions.removeAllPaymentCartItemsByCategory(props.cartCategory)
        )
      : dispatch(
          cartActions.setAllPaymentCartItemsByCategory(props.cartCategory)
        );
  };
  const currentCart = useAppSelector((state) => state.cart.currentCart);
  const paymentCart = useAppSelector((state) => state.cart.paymentCart);

  const areAllItemsInPaymentCart = () => {
    let allItemsInPaymentCart = true;
    const categoryPaymentCart = paymentCart.categories.find(
      (category) => category.category.id === props.cartCategory.category.id
    );
    if (!categoryPaymentCart) {
      allItemsInPaymentCart = false;
    } else {
      currentCart.categories.map((category) => {
        if (category.category.id === categoryPaymentCart.category.id) {
          category.items.map((item) => {
            if (
              !categoryPaymentCart.items.find(
                (paymentItem) =>
                  paymentItem.id === item.id &&
                  paymentItem.quantity == item.quantity
              )
            ) {
              allItemsInPaymentCart = false;
            }
          });
        }
      });
    }
    setChecked(allItemsInPaymentCart);
  };

  useEffect(() => {
    areAllItemsInPaymentCart();
  }, [JSON.stringify(paymentCart)]);

  return (
    <Checkbox
      checked={checked}
      onClick={handleOnClick}
      sx={{
        color: "black",
      }}
      size="small"
    />
  );
}
