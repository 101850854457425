import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import { useState } from "react";
import { CustomForm } from "../../../../../../common/containers/Form/CustomForm";
import CustomNumberField from "../../../../../../common/containers/Form/CustomNumberField";
import CustomSelectField from "../../../../../../common/containers/Form/CustomSelectField";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { transferToBank } from "../../../../services/fundsManagementService";
import { fundsManagementActions } from "../../slices/fundsManagementSlice";
import { TTransferToBankModalProps } from "./types/TTransferToBankModalProps";

const TransferToBankModal = ({
  bankAccountList,
  stripeBalance,
}: TTransferToBankModalProps) => {
  const dispatch = useAppDispatch();
  const isTransferToBankModalOpen = useAppSelector(
    (state) => state.fundsManagement.isTransferToBankModalOpen
  );
  const [currentAmount, setCurrentAmount] = useState(0);

  const handleCloseModal = () => {
    dispatch(fundsManagementActions.setIsTransferToBankModalOpen(false));
  };

  const handleTransfer = async (data: {
    bankAccount: string;
    amount: number;
  }) => {
    console.log(
      `Transferring ${data.amount} to bank account ${data.bankAccount}`
    );
    dispatch(
      transferToBank({
        amount: data.amount,
        bankAccountId: data.bankAccount,
        currency: "EUR",
      })
    );
    handleCloseModal();
  };

  const bankAccountOptions =
    bankAccountList?.data.map((account) => ({
      value: account.id,
      label: `${account.bank_name} - **** ${account.last4}${
        account.status !== "verified" ? " (Non vérifié)" : ""
      }`,
      disabled: account.status !== "verified",
    })) || [];

  const availableBalance =
    stripeBalance?.available.find((balance) => balance.currency === "eur")
      ?.amount || 0;

  const maxAmount = availableBalance / 100;

  const isAmountValid = currentAmount > 0 && currentAmount <= maxAmount;

  return (
    <MuiDialog
      open={isTransferToBankModalOpen}
      onClose={handleCloseModal}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Transfert bancaire</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Solde disponible: {maxAmount.toFixed(2)} €
        </Typography>
        <CustomForm
          onSubmit={handleTransfer}
          onSuccess={handleCloseModal}
          defaultButtonText="Transférer"
          name="transferToBankForm"
          submitDisabled={!isAmountValid}
        >
          <CustomSelectField
            name="bankAccount"
            label="Compte bancaire"
            options={bankAccountOptions}
            validation={{
              required: "Veuillez sélectionner un compte bancaire",
            }}
          />
          <CustomNumberField
            name="amount"
            label="Montant"
            currency="€"
            max={maxAmount}
            onValueChange={setCurrentAmount}
            validation={{
              required: "Le montant est requis",
              min: {
                value: 0.01,
                message: "Le montant doit être supérieur à 0",
              },
              max: {
                value: maxAmount,
                message: "Le montant ne peut pas dépasser le solde disponible",
              },
            }}
          />
        </CustomForm>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal}>Annuler</Button>
      </DialogActions>
    </MuiDialog>
  );
};

export default TransferToBankModal;
