import { Typography } from "@mui/material";
import { TCartRecapHeader } from "./types/TCartRecapHeader";

export default function CartRecapHeader(props: TCartRecapHeader) {
  return (
    <div>
      <Typography className="listItem-heading-large-primary" gutterBottom>
        Détails de la commande
      </Typography>
      <Typography className="listItem-heading-large-secondary" gutterBottom>
        {props.numberOfItems} Articles
      </Typography>
    </div>
  );
}
