import { AddCircleOutlined } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { IMenuEntity } from "../../../../../../common/Entities/IMenuEntity";
import { useAppDispatch } from "../../../../../../common/store/redux";
import { menuActions } from "../../slice/menuSlice";
import { TMenuCreationBarProps } from "./types/TMenusNavigationBarProps";

export default function MenusNavigationBar(props: TMenuCreationBarProps) {
  const dispatch = useAppDispatch();
  const handleSelectMenu = (menu: IMenuEntity) => {
    dispatch(menuActions.setSelectedMenu(menu));
  };
  const handleOpenCreateMenuModal = () => {
    dispatch(menuActions.setIsCreateMenuModalOpen(true));
  };

  return (
    <Grid
      container
      mt={4}
      sx={{ borderBottom: "1px solid", borderColor: "rgba(0, 0, 0, 0.13)" }}
    >
      {props.menuList.length > 0 &&
        props.menuList.map((menu) => {
          return (
            <>
              <Grid
                item
                mr={2}
                className="grid-center"
                onClick={() => {
                  handleSelectMenu(menu);
                }}
                sx={{ cursor: "pointer" }}
              >
                <Typography
                  sx={{
                    fontWeight: `${props.selectedMenu.id! === menu.id ? `600` : `400`}`,
                    fontSize: "20px",
                    borderBottom: `${props.selectedMenu.id! === menu.id ? `2px solid #FBBC04` : `none`}`,
                    "&::first-letter": {
                      textTransform: "uppercase",
                    },
                  }}
                >
                  {menu.name}
                </Typography>
              </Grid>
            </>
          );
        })}
      <Grid item mr={2} className="grid-center">
        <AddCircleOutlined
          sx={{ fontSize: "20px", cursor: "pointer" }}
          onClick={handleOpenCreateMenuModal}
        />
      </Grid>
    </Grid>
  );
}
