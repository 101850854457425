import { SubmitHandler } from "react-hook-form";
import { CustomForm } from "../../../../common/containers/Form/CustomForm";
import CustomTextField from "../../../../common/containers/Form/CustomTextFields";
import { useAppDispatch, useAppSelector } from "../../../../common/store/redux";
import { signUp } from "../../services/authService";
import { TSignUpFormAttributes } from "./types/TSignUpFormAttributes";

import { useNavigate } from "react-router-dom";
import { TOnSignUpRequest } from "../../services/types/Requests/TOnSignUpRequest";
import CustomEmailField from "./CustomEmailField";
import CustomPasswordField from "./CustomPasswordField";
import { Grid } from "@mui/material";

const SignUpForm = () => {
  const dispatch = useAppDispatch();

  const submitHandler: SubmitHandler<TSignUpFormAttributes> = (data) => {
    const requestData: TOnSignUpRequest = {
      email: data.email,
      password: data.password,
      restaurant: { name: data.name },
      userType: { type: "RESTAURANT" },
    };
    dispatch(signUp(requestData));
  };
  const user = useAppSelector((state) => state.auth.user);

  const navigate = useNavigate();

  return (
    <CustomForm
      onSubmit={submitHandler}
      defaultButtonText="S'inscrire"
      onSuccess={() => {
        navigate("/dashboard/");
      }}
      name="sign-up-form"
    >
      <Grid item mb={4}>
        <CustomEmailField name="email" label="Email" />
      </Grid>
      <Grid item mb={4}>
        <CustomPasswordField
          name="password"
          label="Mot de passe"
          validation={{
            minLength: {
              value: 8,
              message: "Le mot de passe doit faire au moins 8 caractères",
            },
            pattern: {
              value:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              message:
                "Le mot de passe doit contenir au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.",
            },
          }}
        />
      </Grid>
      <Grid item mb={4}>
        <CustomTextField
          name="name"
          label="Nom du restaurant"
          validation={{
            required: "Veuillez renseigner le nom de votre restaurant",
          }}
          firstLetterUppercase={true}
        />
      </Grid>
    </CustomForm>
  );
};

export default SignUpForm;
