import React from "react";
import MenuItemBox from "../MenuItemBox/MenuItemBox";
import { Grid, Typography } from "@mui/material";
import { useAppDispatch } from "../../../../../../common/store/redux";
import { itemActions } from "../../slice/itemSlice";

export default function MenuItemSample() {
  const dispatch = useAppDispatch();
  const handleOpenCreateMenuItemModal = () => {
    dispatch(itemActions.setIsCreateMenuItemModalOpen(true));
  };
  return (
    <Grid
      item
      sx={{
        backgroundColor: "white",
        borderRadius: "6px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        width: "244px",
        height: "300px",
        margin: "18px",
        marginBottom: "27px",
        marginTop: "27px",
        cursor: "pointer",
        "&:hover": {
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.4)",
          backgroundColor: "#F6F6F6",
        },
      }}
      onClick={handleOpenCreateMenuItemModal}
    >
      <Grid
        container
        justifyContent={"center"}
        alignContent={"center"}
        alignItems={"center"}
        height={"100%"}
      >
        <Typography fontWeight={"bold"}>Ajouter un article</Typography>
      </Grid>
    </Grid>
  );
}
