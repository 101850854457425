import { useBlocker } from "react-router-dom";

export function useNavigationBlocker(hasUnsavedChanges: boolean) {
  // Block navigating elsewhere when data changed hasnt been saved
  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      hasUnsavedChanges && currentLocation.pathname !== nextLocation.pathname
  );

  const closeWindow = () => {
    blocker.state === "blocked" && blocker.proceed();
  };
  const keepWindow = () => {
    blocker.state === "blocked" && blocker.reset();
  };
  const isLeavingPage = (): boolean => {
    switch (blocker.state) {
      case "blocked":
        return true;
      default:
        return false;
    }
  };
  return {
    closeWindow,
    keepWindow,
    isLeavingPage,
  };
}
