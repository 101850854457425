import { SearchOutlined } from "@mui/icons-material";
import { Grid, InputBase } from "@mui/material";
import { useAppDispatch } from "../../../../common/store/redux";

export default function DashboardHeaderSearchBar() {
  const dispatch = useAppDispatch();
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
  };
  return (
    <Grid container item xs={12} className="search-bar">
      <Grid item xs={4} className="grid-center">
        <SearchOutlined />
      </Grid>
      <Grid item xs={7}>
        <InputBase
          sx={{ fontSize: "14px" }}
          onChange={handleSearch}
          placeholder="Rechercher"
        />
      </Grid>
    </Grid>
  );
}
