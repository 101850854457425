import { createAsyncThunk } from "@reduxjs/toolkit";
import { MethodEnum } from "../../../../../common/modules/enums/MethodEnum";
import { apiRequest } from "../../../../../common/modules/temporary/api";
import { TDeleteMenuCategoryRequest } from "./type/Request/TDeleteMenuCategoryRequest";
import { TGetMenuCategoryRequest } from "./type/Request/TGetMenuCategoryRequest";
import { TDeleteMenuCategoryResponse } from "./type/Responses/TDeleteMenuCategoryResponse";
import { TGetMenuCategoryResponse } from "./type/Responses/TGetMenuCategoryResponse";
import { TUpdateMenuCategoryResponse } from "./type/Responses/TUpdateMenuCategoryResponse";
import { TCreateMenuCategoryRequest } from "./type/Request/TCreateMenuCategoryRequest";
import { TCreateMenuCategoryReseponse } from "./type/Responses/TCreateMenuCategoryReseponse";

export const getCategory = createAsyncThunk(
  "menu-category/find",
  async (request: TGetMenuCategoryRequest, { dispatch, rejectWithValue }) => {
    const response = await apiRequest<TGetMenuCategoryResponse>(
      dispatch,
      `/api/menu-category/find/${request.categoryId}`,
      MethodEnum.GET,
      null
    );
    if (response.success) {
    } else {
      return rejectWithValue(response);
    }
  }
);
export const deleteMenuCategory = createAsyncThunk(
  "menu-category/delete",
  async (
    request: TDeleteMenuCategoryRequest,
    { dispatch, rejectWithValue }
  ) => {
    const response = await apiRequest<TDeleteMenuCategoryResponse>(
      dispatch,
      `/api/menu-category/delete`,
      MethodEnum.DELETE,
      request
    );
    if (response.success) {
      return true;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const updateMenuCategory = createAsyncThunk(
  "menu-category/update",
  async (
    request: TUpdateMenuCategoryResponse,
    { dispatch, rejectWithValue }
  ) => {
    const response = await apiRequest<TUpdateMenuCategoryResponse>(
      dispatch,
      `/api/menu-category/update`,
      MethodEnum.PUT,
      request
    );
    if (response.success) {
      return response.data.category;
    } else {
      return rejectWithValue(response);
    }
  }
);

export const createMenuCategory = createAsyncThunk(
  "menu-category/create",
  async (
    request: TCreateMenuCategoryRequest,
    { dispatch, rejectWithValue }
  ) => {
    const response = await apiRequest<TCreateMenuCategoryReseponse>(
      dispatch,
      `/api/menu-category/create`,
      MethodEnum.POST,
      request
    );
    if (response.success) {
      return response.data.category;
    } else {
      return rejectWithValue(response);
    }
  }
);
