import { createAsyncThunk } from "@reduxjs/toolkit";
import { MethodEnum } from "../../../common/modules/enums/MethodEnum";
import { apiRequest } from "../../../common/modules/temporary/api";
import { TOnGetAllReviewsResponse } from "./types/Responses/TOnGetAllReviewsResponse";
import { reviewsActions } from "../slices/reviewsSlices";

export const getAll = createAsyncThunk(
  "reviews/find-all",
  async (_, { dispatch }) => {
    const response = await apiRequest<TOnGetAllReviewsResponse>(
      dispatch,
      "/api/reviews/find-all",
      MethodEnum.GET,
      null
    );
    if (response.success) {
      dispatch(reviewsActions.setReviewsReport(response.data.report));
    }
  }
);
