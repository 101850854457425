import React from "react";
import CustomButton from "../../../../containers/Button/CustomButton";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { initiateCart } from "../../../../services/cartService";
import { TSendOrderButtonProps } from "./types/TSendOrderButtonProps";
import { cartActions } from "../../../../slices/cartSlice";
import { useNavigate } from "react-router-dom";
import { clearCartState } from "../../../../../../utils/cart/cartLocalStorage";

export default function SendOrderButton(props: TSendOrderButtonProps) {
  const dispatch = useAppDispatch();
  const userRef = useAppSelector((state) => state.cart.currentCart.userRef);
  const table = useAppSelector((state) => state.cart.currentCart.table);
  const navigate = useNavigate();
  const handleOnClick = () => {
    console.log("clicked", props.cart);

    dispatch(initiateCart({ cart: props.cart }));

    navigate(`/customerMenu/orderSent?userRef=${userRef}&table=${table}`);
  };
  return (
    <CustomButton
      buttonText="Commander"
      variant="primary"
      handleOnClick={handleOnClick}
    />
  );
}
