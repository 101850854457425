import { Grid, Typography } from "@mui/material";
import CartItemLeftIcon from "./CartItemLeftIcon";
import { TCartItemProps } from "./types/TCartItemProps";
import QuantityIcons from "../OrderRecapBox/QuantityIcons";

const CartItem = (props: TCartItemProps) => {
  const cartSupplements = props.cartItem.cartSupplements ?? [];

  return (
    <Grid container xs={12}>
      <Grid xs={2} className="grid-center">
        <CartItemLeftIcon
          cartItem={props.cartItem}
          cartIndexes={props.cartIndexes}
          iconType={props.iconType}
        />
      </Grid>
      <Grid container xs={8} pl={1} alignItems={"center"}>
        <Grid xs={12}>
          <Typography
            className={
              props.fontType === "category-item"
                ? `cart-recap-box-category-item-title `
                : `cart-recap-box-item-title`
            }
          >
            {props.cartItem.item.name}
          </Typography>
        </Grid>
        <Grid xs={12}>
          {cartSupplements.length > 0 && (
            <Typography className="listItem-heading-medium-secondary" mt={0.5}>
              {cartSupplements.map((cartSupplement, index) => (
                <span key={index}>
                  {cartSupplement.supplement.name} ({cartSupplement.quantity})
                  {index < cartSupplements.length - 1 && ", "}
                </span>
              ))}
            </Typography>
          )}
        </Grid>
      </Grid>
      {props.showQuantityBox && (
        <Grid className="grid-center" xs={2}>
          <QuantityIcons
            showQuantityBox={props.showQuantityBox}
            cartItem={props.cartItem}
            iconType={props.iconType}
            cartIndexes={props.cartIndexes}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CartItem;
