import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMenuSupplementEntity } from "../../../../../common/Entities/IMenuSupplementEntity";
import { TMenuSupplementSliceState } from "./types/TMenuSupplementSliceState";

const initialState: TMenuSupplementSliceState = {
  selectedSupplement: null,
  supplementList: [],
  hasSupplementsChanged: false,
};

const menuSupplementSlice = createSlice({
  name: "supplement",
  initialState,
  reducers: {
    setSupplementList: (
      state,
      action: PayloadAction<IMenuSupplementEntity[]>
    ) => {
      state.supplementList = action.payload;
    },
    addSupplementToList: (
      state,
      action: PayloadAction<IMenuSupplementEntity>
    ) => {
      state.supplementList.push(action.payload);
      state.hasSupplementsChanged = true;
    },
    removeSupplementFromList: (state, action: PayloadAction<number>) => {
      state.supplementList = state.supplementList.filter(
        (supplement, supplementIndex) => supplementIndex !== action.payload
      );
      state.hasSupplementsChanged = true;
    },
    setSelectedSupplement: (
      state,
      action: PayloadAction<IMenuSupplementEntity | null>
    ) => {
      state.selectedSupplement = action.payload;
    },
  },
});

export const supplementReducer = menuSupplementSlice.reducer;
export const supplementActions = menuSupplementSlice.actions;
