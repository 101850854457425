import { useAppSelector } from "../../../../../../common/store/redux";
import CartRecapBox from "../../../../containers/OrderRecapBox/CartRecapBox";
import { TOrderSentCartRecapBoxProps } from "./types/TOrderSentCartRecapBoxProps";

export default function OrderSentCartRecapBox(
  props: TOrderSentCartRecapBoxProps
) {
  return (
    <CartRecapBox
      showTotalPrice={true}
      cart={props.cart}
      showQuantityBox={false}
      iconType="quantity"
    />
  );
}
