import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMenuCategoryEntity } from "../../../../../common/Entities/IMenuCategoryEntity";
import { TItemSliceState } from "./types/TItemSliceState";
import { IMenuItemEntity } from "../../../../../common/Entities/IMenuItemEntity";

const initialState: TItemSliceState = {
  selectedItem: null,
  isCreateMenuItemModalOpen: false,
  isDeleteMenuItemModalOpen: false,
};

const itemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    setSelectedItem: (state, action: PayloadAction<IMenuItemEntity>) => {
      state.selectedItem = action.payload;
    },
    setIsCreateMenuItemModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isCreateMenuItemModalOpen = action.payload;
    },
    setIsDeleteMenuItemModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isDeleteMenuItemModalOpen = action.payload;
    },
  },
});

export const itemReducer = itemSlice.reducer;

export const itemActions = itemSlice.actions;
