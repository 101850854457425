import { Box, Grid } from "@mui/material";
import { useEffect, useRef } from "react";
import orderSentGif from "../../../../../common/images/order_sent_gif.gif";
import handIcon from "../../../../../common/images/orderSent_hand_icon.png";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/store/redux";
import CartHeader from "../../../containers/Header/CartHeader";
import OrderSentCartRecapBox from "../containers/CartRecapBox/OrderSentCartRecapBox";
import { useLocation, useNavigate } from "react-router-dom";
import { cartActions } from "../../../slices/cartSlice";

const OrderSentPage = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const userRef = urlParams.get("userRef") || "";
  const table = urlParams.get("table") || "";
  const cartState = useAppSelector((state) => state.cart.currentCart);
  const cartRecapBoxRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    console.log("cartRecapBoxRef", cartRecapBoxRef);
    if (cartRecapBoxRef.current) {
      cartRecapBoxRef.current.focus();
    }
    console.log("cartState ordrSent", cartState);
  }, [cartRecapBoxRef]);

  useEffect(() => {
    return () => {
      dispatch(cartActions.clearCart());
    };
  }, [navigate, dispatch]);

  return cartState ? (
    <>
      <Grid
        container
        marginTop={3}
        marginBottom={3}
        padding={2}
        justifyContent={"center"}
      >
        <Grid xs={12} md={8} sm={12} lg={8}>
          <CartHeader
            title={"Commande envoyée"}
            subtitle="La cuisine a reçu votre commande"
            userRef={userRef}
            table={table}
          />
        </Grid>
        <Grid mt={2}>
          <img src={orderSentGif} width={"100%"} height={"100%"} />
        </Grid>
        <Grid xs={12} height={"102px"} mb={3}>
          <Box
            sx={{
              border: 1,
              borderColor: "grey.300",
              borderRadius: 4,
              marginTop: 2,
              boxShadow: 1,
              height: "100%",
              width: "100%",
            }}
            className="grid-center"
            padding={1}
          >
            <Grid container xs={12}>
              <Grid xs={3} paddingLeft={1} className="grid-center">
                <img src={handIcon} />
              </Grid>
              <Grid xs={9} className="grid-center">
                Personnel est informé! <br />
                Pour toute question, n'hésitez pas à nous appeller.
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={12} md={8} sm={12} lg={8}>
          <div ref={cartRecapBoxRef} tabIndex={-1} style={{ outline: "none" }}>
            <OrderSentCartRecapBox cart={cartState} />
          </div>
        </Grid>
      </Grid>
    </>
  ) : (
    <>Votre panier est vide</>
  );
};

export default OrderSentPage;
