import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { customFormActions } from "../../../../common/store/customFormSlice";
import { useAppDispatch, useAppSelector } from "../../../../common/store/redux";
import { cartActions } from "../../slices/cartSlice";
import ScanQrcodeForm from "../Payment/Form/ScanQrcodeForm";

export default function ScanQrcodePage() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const userRef = urlParams.get("userRef");
  const forms = useAppSelector((state) => state.form.forms);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {userRef ? (
        <Grid container>
          <Grid item xs={12} className="grid-center">
            <ScanQrcodeForm userRef={userRef} />
          </Grid>
        </Grid>
      ) : (
        <Typography variant="h6" color={"red"}>
          Impossible d'accéder à cette page
        </Typography>
      )}
    </div>
  );
}
