import { Grid, Typography } from "@mui/material";
import React from "react";
import { TCartItemPriceProps } from "./types/TCartItemPriceProps";

export default function CartItemPrice(props: TCartItemPriceProps) {
  const calculateTotalItemPrice = (): number => {
    const totalItemPrice = props.cartItem.item.price;
    let totalSupplementsPrice = 0;
    props.cartItem.cartSupplements?.map((cartSupplement) => {
      totalSupplementsPrice +=
        cartSupplement.supplement.price * cartSupplement.quantity;
    });
    return props.iconType === "checkbox"
      ? parseFloat(
          (
            ((totalItemPrice + totalSupplementsPrice) *
              props.cartItem.quantity) /
            props.cartItem.quantity
          ).toFixed(2)
        )
      : parseFloat(
          (
            (totalItemPrice + totalSupplementsPrice) *
            props.cartItem.quantity
          ).toFixed(2)
        );
  };
  return (
    <Typography
      className={
        props.iconType == "checkbox"
          ? `priceTag-medium-green`
          : `priceTag-medium-grey`
      }
    >
      {calculateTotalItemPrice()} €
    </Typography>
  );
}
