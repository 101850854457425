import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICartCategoryEntity } from "../../../common/Entities/ICartCategoryEntity";
import { ICartEntity } from "../../../common/Entities/ICartEntity";
import { ICartItemEntity } from "../../../common/Entities/ICartItemEntity";
import {
  clearCartState,
  loadCartState,
  saveCartState,
} from "../../../utils/cart/cartLocalStorage";
import { TAddItemAttributes } from "./types/TAddItemAttributes";
import { TCartIndexes } from "./types/TCartIndexes";
import { TCartState } from "./types/TCartState";
import { TSelectItemAttributes } from "./types/TSelectItemAttributes";
import { TUpdateCartItemAttributes } from "./types/TUpdateCartItemAttributes";
import { ICartSupplementEntity } from "../../../common/Entities/ICartSupplementEntity";

const defaultInitialState: TCartState = {
  currentCart: {
    categories: [],
    userRef: "",
    table: "",
  },
  previousCarts: [],
  paymentCart: {
    categories: [],
    userRef: "",
    table: "",
  },
  totalPrice: 0,
  numberOfItems: 0,
  selectedItem: { cartIndexes: { categoryIndex: -1, itemIndex: -1 } },
  openDeletePopup: false,
  openQuantityPopup: false,
  tip: 0,
  openAddCustomTipPopup: false,
  totalPaymentPrice: 0,
  ordersNbr: 0,
};

const initialState: TCartState = loadCartState() || defaultInitialState;

// Helper function to calculate total price
const calculateTotalCurrentPrice = (cart: ICartEntity | null): number => {
  if (!cart) return 0;

  const total = cart.categories.reduce((categoryTotal, category) => {
    const categoryPrice = category.items.reduce((total, item) => {
      const itemTotal = item.item.price;
      const supplementsTotal =
        item.cartSupplements?.reduce(
          (supTotal, supplement) =>
            supTotal + supplement.supplement.price * supplement.quantity,
          0
        ) ?? 0;

      return total + (itemTotal + supplementsTotal) * item.quantity;
    }, 0);

    return categoryTotal + categoryPrice;
  }, 0);

  return parseFloat(total.toFixed(2));
};

// Helper function to calculate total selected price
const calculateTotalPaymentPrice = (cart: ICartEntity | null): number => {
  if (!cart) return 0;

  return cart.categories.reduce((categoryTotal, category) => {
    const categoryPrice = category.items.reduce((total, item) => {
      const itemTotal = item.item.price;
      const supplementsTotal = item.cartSupplements?.reduce(
        (supTotal, supplement) =>
          supTotal + supplement.supplement.price * supplement.quantity,
        0
      );
      return (
        total +
        (itemTotal * item.quantity + (supplementsTotal || 0) * item.quantity)
      );
    }, 0);

    return categoryTotal + categoryPrice;
  }, 0);
};

// Helper function to calculate number of items
const calculateNumberOfItems = (cart: ICartEntity | null): number => {
  if (!cart) return 0;

  return cart.categories.reduce((categoryTotal, category) => {
    return (
      categoryTotal +
      category.items.reduce((total, item) => total + item.quantity, 0)
    );
  }, 0);
};

// Helper function to update totalPrice and numberOfItems
const updateCart = (state: TCartState) => {
  state.totalPrice = calculateTotalCurrentPrice(state.currentCart);
  state.numberOfItems = calculateNumberOfItems(state.currentCart);
  state.totalPaymentPrice = calculateTotalPaymentPrice(state.paymentCart);
};

const slice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCurrentCart: (state, action: PayloadAction<ICartEntity>) => {
      state.currentCart = action.payload;
      updateCart(state);
    },
    fromCartListToCurrentCart: (
      state,
      action: PayloadAction<ICartEntity[]>
    ) => {
      const newCart: ICartEntity = {
        categories: [],
        userRef: action.payload[0].userRef,
        table: action.payload[0].table,
      };

      action.payload.forEach((cart) => {
        cart.categories.forEach((cartCategory) => {
          let foundCategory = newCart.categories.find(
            (newCartCategory) =>
              newCartCategory.category.id === cartCategory.category.id
          );

          if (!foundCategory) {
            foundCategory = { ...cartCategory, items: [] };
            newCart.categories.push(foundCategory);
          }

          cartCategory.items.forEach((cartItem) => {
            const foundItem = foundCategory!.items.find(
              (newCartItem) => newCartItem.item.id === cartItem.item.id
            );

            if (foundItem) {
              const isSameSupplement = (
                a: ICartSupplementEntity[],
                b: ICartSupplementEntity[]
              ) => {
                if (a.length !== b.length) return false;
                a.sort((x, y) => (x.id ?? 0) - (y.id ?? 0));
                b.sort((x, y) => (x.id ?? 0) - (y.id ?? 0));
                return a.every(
                  (sup, index) =>
                    sup.supplement.id === b[index].supplement.id &&
                    sup.quantity === b[index].quantity
                );
              };

              if (
                (cartItem.cartSupplements === null &&
                  foundItem.cartSupplements === null) ||
                (cartItem.cartSupplements &&
                  foundItem.cartSupplements &&
                  isSameSupplement(
                    cartItem.cartSupplements,
                    foundItem.cartSupplements
                  ))
              ) {
                foundItem.quantity += cartItem.quantity;
              } else {
                foundCategory!.items.push({ ...cartItem });
              }
            } else {
              foundCategory!.items.push({ ...cartItem });
            }
          });
        });
      });

      state.currentCart = newCart;
      console.log("actionPayloadCart", action.payload);
      console.log("newCart", newCart);
      updateCart(state);
    },

    addCurrentCartItem: (state, action: PayloadAction<TAddItemAttributes>) => {
      const category = state.currentCart.categories.find(
        (cartCategory) =>
          cartCategory.category.id === action.payload.category.id
      );

      if (category) {
        const foundItem = category.items.find(
          (cartItem) => cartItem.item.id === action.payload.item.item.id
        );

        const supplementsMatch =
          foundItem &&
          foundItem.cartSupplements?.length ===
            action.payload.item.cartSupplements?.length &&
          foundItem.cartSupplements?.every(
            (cartSupplement, index) =>
              cartSupplement.supplement.id ===
                action.payload.item.cartSupplements?.[index].supplement.id &&
              cartSupplement.quantity ===
                action.payload.item.cartSupplements?.[index].quantity
          );

        if (foundItem && supplementsMatch) {
          foundItem.quantity += 1;
        } else {
          const newItem = { ...action.payload.item };
          category.items.push(newItem);
        }
      } else {
        const newItem = { ...action.payload.item };
        state.currentCart.categories.push({
          category: action.payload.category,
          items: [newItem],
        });
      }

      updateCart(state);
      saveCartState(state);
    },
    deleteCurrentCartItem: (state, action: PayloadAction<TCartIndexes>) => {
      state.currentCart.categories = state.currentCart.categories
        .map((cartCategory, categoryIndex) => {
          if (categoryIndex === action.payload.categoryIndex) {
            cartCategory.items = cartCategory.items.filter(
              (_, itemIndex) => itemIndex !== action.payload.itemIndex
            );
          }
          return cartCategory;
        })
        .filter((cartCategory) => cartCategory.items.length > 0);

      updateCart(state);
      saveCartState(state);
    },
    updateCurrentCartItem: (
      state,
      action: PayloadAction<TUpdateCartItemAttributes>
    ) => {
      state.currentCart.categories = state.currentCart.categories.map(
        (cartCategory, categoryIndex) => {
          if (categoryIndex === action.payload.cartIndexes.categoryIndex) {
            cartCategory.items = cartCategory.items.map(
              (cartItem, itemIndex) => {
                return itemIndex === action.payload.cartIndexes.itemIndex
                  ? action.payload.cartItem
                  : cartItem;
              }
            );
          }
          return cartCategory;
        }
      );

      updateCart(state);
      saveCartState(state);
    },
    addCurrentCartItemQuantity: (
      state,
      action: PayloadAction<TCartIndexes>
    ) => {
      state.currentCart.categories = state.currentCart.categories.map(
        (cartCategory, categoryIndex) => {
          if (categoryIndex === action.payload.categoryIndex) {
            cartCategory.items = cartCategory.items.map(
              (cartItem, itemIndex) => {
                return itemIndex === action.payload.itemIndex
                  ? { ...cartItem, quantity: cartItem.quantity + 1 }
                  : cartItem;
              }
            );
          }
          return cartCategory;
        }
      );

      updateCart(state);
      saveCartState(state);
    },
    removeCurrentCartItemQuantity: (
      state,
      action: PayloadAction<TCartIndexes>
    ) => {
      state.currentCart.categories = state.currentCart.categories.map(
        (cartCategory, categoryIndex) => {
          if (categoryIndex === action.payload.categoryIndex) {
            cartCategory.items = cartCategory.items.map(
              (cartItem, itemIndex) => {
                return itemIndex === action.payload.itemIndex
                  ? {
                      ...cartItem,
                      quantity: Math.max(cartItem.quantity - 1, 1),
                    }
                  : cartItem;
              }
            );
          }
          return cartCategory;
        }
      );

      updateCart(state);
      saveCartState(state);
    },
    setOpenDeletePopup: (state, action: PayloadAction<boolean>) => {
      state.openDeletePopup = action.payload;
      saveCartState(state);
    },
    setOpenQuantityPopup: (state, action: PayloadAction<boolean>) => {
      state.openQuantityPopup = action.payload;
      saveCartState(state);
    },
    setOpenAddCustomTipPopup: (state, action: PayloadAction<boolean>) => {
      state.openAddCustomTipPopup = action.payload;
      saveCartState(state);
    },
    clearCart: (state) => {
      state.currentCart = {
        categories: [],
        userRef: state.currentCart.userRef,
        table: state.currentCart.table,
      };
      clearCartState();
    },
    setTip: (state, action: PayloadAction<number>) => {
      state.tip = action.payload;
    },
    setUserRef: (state, action: PayloadAction<string>) => {
      state.currentCart.userRef = action.payload;
    },
    setTable: (state, action: PayloadAction<string>) => {
      state.currentCart.table = action.payload;
    },
    resetCart: (state) => {
      state.currentCart.categories = [];
      state.currentCart.id = undefined;
      state.currentCart.cartPOS = undefined;
    },
    setPreviousCarts: (state, action: PayloadAction<ICartEntity[]>) => {
      state.previousCarts = action.payload;
    },
    initializePaymentCart: (state) => {
      state.paymentCart = {
        categories: [],
        userRef: state.currentCart.userRef,
        table: state.currentCart.table,
      };
    },
    addItemToPaymentCart: (state, action: PayloadAction<ICartItemEntity>) => {
      console.log("addItemTo", action.payload);
      const categoryCurrentCart = state.currentCart.categories.find(
        (category) => category.category.id === action.payload.item.categoryId
      );
      console.log("categoryCurrentCart", categoryCurrentCart);
      if (categoryCurrentCart) {
        const itemCurrentCart = categoryCurrentCart.items.find(
          (item) => item.id === action.payload.id
        );
        const categoryPaymentCart = state.paymentCart.categories.find(
          (category) => category.id === categoryCurrentCart.id
        );
        if (categoryPaymentCart) {
          const itemPaymentCart = categoryPaymentCart.items.find(
            (item) => item.id === action.payload.id
          );
          if (itemPaymentCart && itemCurrentCart) {
            itemPaymentCart.quantity =
              (itemPaymentCart.quantity < itemCurrentCart.quantity &&
                itemPaymentCart.quantity + 1) ||
              itemPaymentCart.quantity;
          } else {
            categoryPaymentCart.items = [
              ...categoryPaymentCart.items,
              { ...action.payload, quantity: 1 },
            ];
          }
        } else {
          console.log("!categoryCurrentCart", categoryCurrentCart);
          state.paymentCart.categories = [
            ...state.paymentCart.categories,
            {
              ...categoryCurrentCart,
              items: [{ ...action.payload, quantity: 1 }],
            },
          ];
        }
      }
      updateCart(state);
    },
    removeItemFromPaymentCart: (
      state,
      action: PayloadAction<ICartItemEntity>
    ) => {
      const categoryCurrentCart = state.currentCart.categories.find(
        (category) => category.id === action.payload.cartCategoryId
      );
      if (categoryCurrentCart) {
        const categoryPaymentCart = state.paymentCart.categories.find(
          (category) => category.id === categoryCurrentCart.id
        );
        if (categoryPaymentCart) {
          const itemPaymentCart = categoryPaymentCart.items.find(
            (item) => item.id === action.payload.id
          );
          if (itemPaymentCart) {
            if (itemPaymentCart.quantity > 0) {
              itemPaymentCart.quantity = itemPaymentCart.quantity - 1;
            }
            state.paymentCart.categories = [
              ...state.paymentCart.categories.filter((category) => {
                category.items = category.items.filter(
                  (item) => item.quantity > 0
                );
                return category.items.length > 0;
              }),
            ];
          }
        }
      }
      updateCart(state);
    },
    removeAllPaymentCartItemsByCategory: (
      state,
      action: PayloadAction<ICartCategoryEntity>
    ) => {
      const categoryPaymentCart = state.paymentCart.categories.find(
        (category) => category.id === action.payload.id
      );
      const categoryCurrentCart = state.currentCart.categories.find(
        (category) => category.id === action.payload.id
      );
      if (categoryCurrentCart) {
        if (categoryPaymentCart) {
          state.paymentCart.categories = [
            ...state.paymentCart.categories.filter(
              (category) => category.id !== categoryPaymentCart.id
            ),
          ];
        }
      }
      updateCart(state);
    },
    setAllPaymentCartItemsByCategory: (
      state,
      action: PayloadAction<ICartCategoryEntity>
    ) => {
      const categoryPaymentCart = state.paymentCart.categories.find(
        (category) => category.id === action.payload.id
      );
      const categoryCurrentCart = state.currentCart.categories.find(
        (category) => category.id === action.payload.id
      );
      if (categoryCurrentCart) {
        if (categoryPaymentCart) {
          categoryPaymentCart.items = [...categoryCurrentCart.items];
        } else {
          state.paymentCart.categories = [
            ...state.paymentCart.categories,
            { ...categoryCurrentCart },
          ];
        }
      }
      updateCart(state);
    },
    selectAllCurrentCart: (state) => {
      state.paymentCart.categories = [...state.currentCart.categories];
      updateCart(state);
    },
    resetPaymentCart: (state) => {
      state.paymentCart.categories = [];
      updateCart(state);
    },
    setSelectedCurrentCartItem: (
      state,
      action: PayloadAction<TSelectItemAttributes>
    ) => {
      state.selectedItem.cartIndexes = action.payload.cartIndexes;
      updateCart(state);
    },
    setOrdersNbr: (state, action: PayloadAction<number>) => {
      state.ordersNbr = action.payload;
    },
  },
});

export const cartReducer = slice.reducer;
export const cartActions = slice.actions;
