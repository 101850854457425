import { Grid } from "@mui/material";
import StatsBox from "../../../Account/modules/FundsManagement/containers/StatsBox/StatsBox";
import { TAppreciationStatBoxProps } from "./types/TAppreciationStatBoxProps";
import {
  GrowthCalculator,
  IGrowthDataEntity,
} from "../../../../common/Entities/IGrowthDataEntity";
import { IReviewsEntity } from "../../../../common/Entities/IReviewsEntity";

const defaultReviewTypes = ["MAUVAIS", "NORMAL", "BON", "EXCELLENT"];

export default function AppreciationStatBox(props: TAppreciationStatBoxProps) {
  const reviewIndicators = props.reviewsIndicators || [];

  const calculateGrowth = (reviewsList: IReviewsEntity[]): number => {
    console.log("reviewsList", reviewsList);
    const growthData: IGrowthDataEntity[] = reviewsList.map((review) => ({
      date: new Date(review.date),
      value: 1,
    }));
    console.log("growthData", growthData);
    const calculator = new GrowthCalculator(growthData);
    const growth = calculator.calculateGrowth();
    console.log("growth", growth);
    return growth || 0;
  };

  return (
    <>
      {defaultReviewTypes.map((type) => {
        const reviewIndicator = reviewIndicators.find(
          (ri) => ri.type === type
        ) || {
          type,
          reviewsList: [],
          monthGrowthPercentage: 0,
        };

        return (
          <StatsBox
            key={type}
            title={type}
            statValue={reviewIndicator.reviewsList.length}
            growthPercentage={calculateGrowth(reviewIndicator.reviewsList)}
            hideCurrency={true}
            growthTimeframe={"MONTH"}
            width="191px"
            height="119px"
          />
        );
      })}
    </>
  );
}
