import { Grid } from "@mui/material";
import CartItem from "../CartItem/CartItem";
import CartItemPrice from "./CartItemPrice";
import { TCartItemNoCategoryProps } from "./types/TCartItemNoCategoryProps";

export default function CartItemNoCategory(props: TCartItemNoCategoryProps) {
  return (
    <>
      {props.iconType === "checkbox" ? (
        Array.from({ length: props.item.quantity }).map((_, i) => (
          <Grid container key={i}>
            <Grid item xs={9}>
              <CartItem
                fontType="item"
                cartIndexes={{
                  categoryIndex: props.categoryIndex,
                  itemIndex: props.itemIndex,
                }}
                cartItem={props.item}
                iconType={props.iconType}
                showQuantityBox={props.showQuantityBox}
              />
            </Grid>
            <Grid container item xs={3} className="grid-center">
              <Grid item>
                <CartItemPrice
                  iconType={props.iconType}
                  cartItem={props.item}
                />
              </Grid>
            </Grid>
          </Grid>
        ))
      ) : (
        <Grid container>
          <Grid item xs={9}>
            <CartItem
              fontType="item"
              cartIndexes={{
                categoryIndex: props.categoryIndex,
                itemIndex: props.itemIndex,
              }}
              cartItem={props.item}
              iconType={props.iconType}
              showQuantityBox={props.showQuantityBox}
            />
          </Grid>
          <Grid container item xs={3} className="grid-center">
            <Grid item>
              <CartItemPrice iconType={props.iconType} cartItem={props.item} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
