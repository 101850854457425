import React, { useEffect, useState } from "react";
import { CustomForm } from "../../../../../common/containers/Form/CustomForm";
import CustomTextField from "../../../../../common/containers/Form/CustomTextFields";
import { Grid } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/store/redux";
import { cartActions } from "../../../slices/cartSlice";
import { SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { customFormActions } from "../../../../../common/store/customFormSlice";
import { ICustomFormEntity } from "../../../../../common/Entities/ICustomFormEntity";
import { TAddCustomTipFormAttributes } from "./types/TAddCustomTipFormAttributes";

export default function AddCustomTipForm() {
  const dispatch = useAppDispatch();
  const forms = useAppSelector((state) => state.form.forms);

  const submitHandler: SubmitHandler<TAddCustomTipFormAttributes> = (data) => {
    dispatch(cartActions.setTip(data.customTip));
    dispatch(customFormActions.setFormSuccess("add-custom-tip-form"));
  };
  const successHandler = () => {
    dispatch(cartActions.setOpenAddCustomTipPopup(false));
  };
  return (
    <CustomForm
      onSubmit={submitHandler}
      onSuccess={successHandler}
      defaultButtonText="Ajouter"
      name="add-custom-tip-form"
    >
      <Grid container className="grid-center" xs={12}>
        <Grid>
          {/*<MaxQuantityField
            name="customTip"
            label="Montant"
            validation={{
              required: " ",
            }}
          />*/}
        </Grid>
      </Grid>
    </CustomForm>
  );
}
