import { Grid } from "@mui/material";
import React from "react";
import DashboardHeaderSearchBar from "../SearchBar/SearchBar";
import {
  NotificationsOutlined,
  Settings,
  SettingsOutlined,
} from "@mui/icons-material";

export default function DashboardHeader() {
  return (
    <>
      <Grid item pl={2} pr={6}>
        <DashboardHeaderSearchBar />
      </Grid>
      <Grid item pl={2} pr={2}>
        <SettingsOutlined sx={{ color: "#667085" }} />
      </Grid>
      <Grid item pl={2} pr={2}>
        <NotificationsOutlined sx={{ color: "#667085" }} />
      </Grid>
    </>
  );
}
