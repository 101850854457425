import React, { useState } from "react";
import { PanoramaOutlined, DeleteOutlineOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useAppDispatch } from "../../../../../../common/store/redux";
import { TMenuItemImageUploadBoxProps } from "./types/TMenuItemImageUploadBoxProps";

export default function MenuItemImageUploadBox(
  props: TMenuItemImageUploadBoxProps
) {
  const [hovered, setHovered] = useState(false);
  const dispatch = useAppDispatch();

  const handleHoverEnter = () => {
    setHovered(true);
  };

  const handleHoverLeave = () => {
    setHovered(false);
  };

  const handleDelete = () => {
    /*dispatch(
      onUpdate({
        id: props.selectedMenuItem.id!,
        image: null,
        name: props.selectedMenuItem.name,
        description: props.selectedMenuItem.description,
        price: props.selectedMenuItem.price,
        position: props.selectedMenuItem.position,
        categoryId: props.selectedMenuItem.categoryId,
        inactiveToday: props.selectedMenuItem.inactiveToday,
      })
    );*/
  };

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log("inactive today", props.selectedMenuItem.inactiveToday);
      /*dispatch(
        onUpdate({
          id: props.selectedMenuItem.id!,
          image: file,
          name: props.selectedMenuItem.name,
          description: props.selectedMenuItem.description,
          price: props.selectedMenuItem.price,
          position: props.selectedMenuItem.position,
          categoryId: props.selectedMenuItem.categoryId,
          inactiveToday: props.selectedMenuItem.inactiveToday,
        })
      );*/
    }
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        backgroundColor: hovered ? "#f0f0f0" : "#ffffff",
        border: hovered ? "1px dashed #ccc" : "1px solid #ccc",
        cursor: !props.selectedMenuItem.menuItemImage.image.url
          ? "pointer"
          : "default",
      }}
    >
      {!props.selectedMenuItem.menuItemImage.image.url ? (
        <>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="file-input"
            onChange={handleUpload}
          />
          <label
            htmlFor="file-input"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <PanoramaOutlined
              style={{
                width: "50%",
                height: "50%",
                color: hovered ? "rgba(0, 0, 0, 0.6)" : "rgba(0, 0, 0, 0.3)",
              }}
            />
          </label>
        </>
      ) : (
        <>
          <img
            src={props.selectedMenuItem.menuItemImage.image.url}
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
          {hovered && (
            <IconButton
              onClick={handleDelete}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                color: "#000",
              }}
            >
              <DeleteOutlineOutlined fontSize="small" />
            </IconButton>
          )}
        </>
      )}
    </div>
  );
}
