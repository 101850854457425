import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Stripe from "stripe";
import { TFundsManagementState } from "./types/TFundsManagementState";

const initialState: TFundsManagementState = {
  stripeBalance: null,
  orders: null,
  transferList: null,
  isTransferToBankModalOpen: false,
};

const fundsManagementSlice = createSlice({
  name: "fundsManagement",
  initialState,
  reducers: {
    setStripeBalance: (state, action: PayloadAction<Stripe.Balance>) => {
      state.stripeBalance = action.payload;
    },
    setTransferList: (
      state,
      action: PayloadAction<Stripe.ApiList<Stripe.Payout>>
    ) => {
      state.transferList = action.payload;
    },
    setIsTransferToBankModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isTransferToBankModalOpen = action.payload;
    },
  },
});

export const fundsManagementReducer = fundsManagementSlice.reducer;
export const fundsManagementActions = fundsManagementSlice.actions;
