import { AddCircleOutlined } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { useEffect } from "react";
import { TMenuCategoryNavigationBarProps } from "./types/TMenuCategoryNavigationBarProps";
import { categoryActions } from "../../slice/categorySlice";
import { IMenuCategoryEntity } from "../../../../../../common/Entities/IMenuCategoryEntity";
import CreateMenuCategoryModal from "../Modals/CreateMenuCategoryModal";

export default function MenuCategoryNavigationBar(
  props: TMenuCategoryNavigationBarProps
) {
  const dispatch = useAppDispatch();
  const selectedCategory = useAppSelector(
    (state) => state.category.selectedCategory
  );

  const handleSelectCategory = (category: IMenuCategoryEntity) => {
    dispatch(categoryActions.setSelectedCategory(category));
  };
  const handleOpenCreateMenuCategoryModal = () => {
    dispatch(categoryActions.setIsCreateCategoryModalOpen(true));
  };
  useEffect(() => {
    //Check if there is a category if so select the first one if not propose to create a new one
    if (props.selectedMenu.categories.length > 0) {
      handleSelectCategory(props.selectedMenu.categories[0]);
    } else {
      handleOpenCreateMenuCategoryModal();
    }
  }, []);

  return (
    <>
      <CreateMenuCategoryModal selectedMenu={props.selectedMenu} />
      <Typography fontWeight={"bold"} mr={1}>
        Menu :
      </Typography>
      <Typography
        sx={{
          "&::first-letter": {
            textTransform: "uppercase",
          },
        }}
      >
        {props.selectedMenu.name}
      </Typography>
      <Grid
        container
        mt={4}
        sx={{ borderBottom: "1px solid", borderColor: "rgba(0, 0, 0, 0.13)" }}
      >
        {props.selectedMenu.categories.map((category, index) => {
          return (
            <Grid
              key={index}
              item
              mr={2}
              className="grid-center"
              onClick={() => {
                handleSelectCategory(category);
              }}
              sx={{ cursor: "pointer" }}
            >
              <Typography
                sx={{
                  fontWeight: `${selectedCategory && category.id === selectedCategory.id ? `600` : `400`}`,
                  borderBottom: `${selectedCategory && category.id === selectedCategory.id ? `2px solid #FBBC04` : `none`}`,
                  fontSize: "20px",
                  "&::first-letter": {
                    textTransform: "uppercase",
                  },
                }}
              >
                {category.name}
              </Typography>
            </Grid>
          );
        })}

        <Grid
          item
          mr={2}
          className="grid-center"
          onClick={handleOpenCreateMenuCategoryModal}
          sx={{ cursor: "pointer" }}
        >
          {props.selectedMenu.categories.length === 0 && (
            <Typography pr={2}>Ajouter une catégorie </Typography>
          )}
          <AddCircleOutlined sx={{ fontSize: "20px" }} />
        </Grid>
      </Grid>
    </>
  );
}
