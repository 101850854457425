import { Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../../common/store/redux";
import MenuBox from "../MenuBox/MenuBox";
import MenusNavigationBar from "../MenusNavigationBar/MenusNavigationBar";
import CreateMenuModal from "../Modals/CreateMenuModal";
import { TMainMenuCreationPageProps } from "./types/TMainMenuCreationPageProps";
import { selectSelectedMenu } from "../../selectors/menuSelector";

export default function MainMenuCreationPage(
  props: TMainMenuCreationPageProps
) {
  const selectedMenu = useAppSelector(selectSelectedMenu);
  let menuRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const [disableScroll, setDisableScroll] = useState(true);

  const scrollToMenu = (menuId: number) => {
    const element = menuRefs.current[menuId];
    if (element && !disableScroll) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    if (selectedMenu && menuRefs.current[selectedMenu.id!]) {
      scrollToMenu(selectedMenu.id!);
    }
  }, [JSON.stringify(selectedMenu)]);

  useEffect(() => {
    setDisableScroll(false);
  }, []);

  const navigate = useNavigate();

  return selectedMenu ? (
    <>
      <CreateMenuModal menuList={props.menuList} />
      <Grid container pb={10}>
        <MenusNavigationBar
          selectedMenu={selectedMenu}
          menuList={props.menuList}
        />
        <Grid item md={12} lg={12} xl={12}>
          <MenuBox menuList={props.menuList} menuRefs={menuRefs} />
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
}
