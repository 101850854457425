import { useEffect, useState } from "react";
import accountOrdersIcon from "../../../../../../common/images/account_orders_icon.png";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { findAllByUserRefAndDate } from "../../../../../Cart/services/cartService";
import StatsBox from "../StatsBox/StatsBox";
import {
  GrowthCalculator,
  IGrowthDataEntity,
} from "../../../../../../common/Entities/IGrowthDataEntity";

export default function AccountOrders() {
  const [statValue, setStatValue] = useState<number>(0);
  const previousCarts = useAppSelector((state) => state.cart.previousCarts);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const now = new Date();
    const startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    dispatch(findAllByUserRefAndDate({ startDate: startDate, endDate: now }));
  }, []);
  const [growthPercentage, setGrowthPercentage] = useState<number>(0);

  useEffect(() => {
    if (previousCarts && previousCarts.length > 0) {
      setStatValue(
        previousCarts.filter((cart) => cart.status === "CONFIRMED").length
      );
      const growthData: IGrowthDataEntity[] = previousCarts.map(
        (previousCarts) => ({
          date: new Date(previousCarts.date!),
          value: 1,
        })
      );
      const calculator = new GrowthCalculator(growthData);
      const growth = calculator.calculateGrowth();
      console.log("growth", growth);
      setGrowthPercentage(growth || 0);
    }
  }, [JSON.stringify(previousCarts)]);
  return (
    <>
      <StatsBox
        statValue={statValue}
        hideCurrency={true}
        title="Commandes"
        img={{ url: accountOrdersIcon }}
        growthPercentage={growthPercentage}
        growthTimeframe={"MONTH"}
      />
    </>
  );
}
