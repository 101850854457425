import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { cartActions } from "../../../../slices/cartSlice";
import { TSelectAllCartButtonProps } from "./types/TSelectAllCartButtonProps";

export default function SelectAllCartButton(props: TSelectAllCartButtonProps) {
  const [checked, setChecked] = useState(true);
  const currentCart = useAppSelector((state) => state.cart.currentCart);
  const paymentCart = useAppSelector((state) => state.cart.paymentCart);

  const isAllCartSelected = () => {
    let allCartSelected = true;
    console.log("paymentCart", paymentCart);
    currentCart.categories.map((category) => {
      const categoryPaymentCart = paymentCart.categories.find(
        (paymentCategory) =>
          paymentCategory.category.id === category.category.id
      );
      if (!categoryPaymentCart) {
        console.log("category non trouvé");
        allCartSelected = false;
        return;
      }
      console.log("category trouvé");
      category.items.map((item) => {
        if (
          !categoryPaymentCart.items.find(
            (paymentItem) =>
              paymentItem.id === item.id &&
              paymentItem.quantity === item.quantity
          )
        ) {
          allCartSelected = false;
        }
      });
    });
    setChecked(allCartSelected);
  };

  useEffect(() => {
    isAllCartSelected();
  }, [JSON.stringify(paymentCart)]);
  const dispatch = useAppDispatch();
  const handleOnClick = () => {
    checked
      ? dispatch(cartActions.resetPaymentCart())
      : dispatch(cartActions.selectAllCurrentCart());
  };
  return (
    <Grid>
      {checked ? (
        <Button className="cart-select-all-button" onClick={handleOnClick}>
          - Tout Déselectionner
        </Button>
      ) : (
        <Button className="cart-select-all-button" onClick={handleOnClick}>
          + Tout Payer
        </Button>
      )}
    </Grid>
  );
}
