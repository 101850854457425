import { ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import MyApp from "./common/containers/App/App";
import MenuProtectedRoute from "./common/containers/ProtectedRoutes/MenuProtectedRoute";
import RestaurantProtectedRoute from "./common/containers/ProtectedRoutes/RestaurantProtectedRoute";
import { lightTheme } from "./common/containers/Theme/lightTheme";
import { store } from "./common/store/store";
import "./index.scss";
import SignInPage from "./modules/Auth/containers/SignInPage";
import SignUpPage from "./modules/Auth/containers/SignUpPage";
import ScanQrcodePage from "./modules/Cart/modules/Initialization/ScanQrcodePage";
import CartPaymentPage from "./modules/Cart/modules/Payment/pages/CartPaymentPage";
import CartValidationPage from "./modules/Cart/modules/Validation/pages/CartValidationPage";
import OrderSentPage from "./modules/Cart/modules/Validation/pages/OrderSentPage";
import MainDashboardPage from "./modules/Dashboard/containers/Pages/MainDashboardPage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={lightTheme}>
        <MyApp>
          <Routes>
            <Route element={<RestaurantProtectedRoute />}>
              <Route path="/dashboard/*" element={<MainDashboardPage />} />
              <Route path="/auth/signin" element={<SignInPage />} />
              <Route path="/auth/signup" element={<SignUpPage />} />
            </Route>
            <Route path={"/"} element={<>Page d'accueil</>} />
            <Route
              path="customerMenu/*"
              element={
                <MenuProtectedRoute>
                  <Routes>
                    <Route
                      path="empty"
                      element={/*{<MenuPreviewPage />}*/ <></>}
                    />
                    <Route path="validation" element={<CartValidationPage />} />
                    <Route path="orderSent" element={<OrderSentPage />} />
                    <Route path="payment" element={<CartPaymentPage />} />
                    <Route path="scan" element={<ScanQrcodePage />} />
                  </Routes>
                </MenuProtectedRoute>
              }
            />
          </Routes>
        </MyApp>
      </ThemeProvider>
    </Provider>
  );
};
const router = createBrowserRouter(
  createRoutesFromElements(<Route path="*" element={<App />} />)
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
