import { useEffect, useState } from "react";
import {
  GrowthCalculator,
  IGrowthDataEntity,
} from "../../../../../../common/Entities/IGrowthDataEntity";
import accountEarningsIcon from "../../../../../../common/images/account_earnings_icon.png";
import StatsBox from "../StatsBox/StatsBox";
import { TAccountEarningsProps } from "./types/TAccountEarningsProps";

export default function AccountEarnings(props: TAccountEarningsProps) {
  const [statValue, setStatValue] = useState<number>(0);
  const [growthPercentage, setGrowthPercentage] = useState<number>(0);

  useEffect(() => {
    let totalEarnings = 0;
    if (props.payments) {
      totalEarnings = props.payments.reduce((previousBalance, payment) => {
        return previousBalance + parseFloat(`${payment.amount}`);
      }, 0);

      const growthData: IGrowthDataEntity[] = props.payments.map((payment) => ({
        date: new Date(payment.date),
        value: parseFloat(`${payment.amount}`),
      }));
      const calculator = new GrowthCalculator(growthData);
      const growth = calculator.calculateGrowth();
      console.log("growth", growth);
      setGrowthPercentage(growth || 0);
    }
    setStatValue(totalEarnings);
  }, [JSON.stringify(props.payments)]);

  return (
    <>
      <StatsBox
        statValue={statValue}
        title="Revenus"
        img={{ url: accountEarningsIcon }}
        growthPercentage={growthPercentage}
        growthTimeframe={"MONTH"}
      />
    </>
  );
}
