import { MoreVertOutlined } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch } from "../../../../../../common/store/redux";
import { TMoreItemVerticalMenuProps } from "./types/TMoreItemVerticalMenuProps";
import { deleteMenuItem, updateMenuItem } from "../../service/itemServices";
import { menuActions } from "../../../menu/slice/menuSlice";
import { IMenuEntity } from "../../../../../../common/Entities/IMenuEntity";
import { IMenuCategoryEntity } from "../../../../../../common/Entities/IMenuCategoryEntity";
import { itemActions } from "../../slice/itemSlice";

const MoreItemVerticalMenu = (props: TMoreItemVerticalMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // State for anchor element (position for menu)
  const dispatch = useAppDispatch();

  // Open menu handler
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget); // Set anchor element to the clicked element
  };
  const handleUpdateItemActive = async (active: boolean) => {
    const resultAction = await dispatch(
      updateMenuItem({
        menuItem: { ...props.selectedItem, inactiveToday: active },
      })
    );
    if (updateMenuItem.fulfilled.match(resultAction)) {
      // The update was successful
      const updatedCategory: IMenuCategoryEntity[] =
        props.selectedMenu.categories.map((category) => {
          if (category.id === props.selectedItem.categoryId) {
            return {
              ...category,
              itemList: category.itemList.map((item) => {
                if (item.id === props.selectedItem.id) {
                  return { ...item, inactiveToday: active };
                }
                return item;
              }),
            };
          }
          return category;
        });
      const newMenu: IMenuEntity = {
        ...props.selectedMenu,
        categories: updatedCategory,
      };
      console.log("newMenu", newMenu);
      dispatch(menuActions.updateMenu(newMenu));
      handleMenuClose();
      return resultAction.payload; // Return the updated menu
    } else if (updateMenuItem.rejected.match(resultAction)) {
      // The update failed
      if (resultAction.payload) {
        // We have an error response from the API
        throw new Error(
          "Une erreur est survenue. Veuillez réessayer plus tard."
        );
      } else {
        // We have an error that occurred during the thunk execution
        throw resultAction.error;
      }
    }
  };

  // Close menu handler
  const handleMenuClose = () => {
    setAnchorEl(null); // Close menu by resetting anchor element
  };
  const handleDeleteMenuItem = async () => {
    dispatch(itemActions.setIsDeleteMenuItemModalOpen(true));
  };

  return (
    <>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        sx={{
          color: "black",
          "&:hover": {
            backgroundColor: "transparent", // Remove hover background color
          },
        }}
        disableRipple // Disable ripple effect
      >
        <MoreVertOutlined />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {props.selectedItem.inactiveToday ? (
          <>
            <MenuItem
              onClick={() => {
                handleUpdateItemActive(false);
              }}
            >
              Réactiver l'article
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              onClick={() => {
                handleUpdateItemActive(true);
              }}
            >
              Désactiver aujourd'hui
            </MenuItem>
          </>
        )}

        <MenuItem onClick={handleDeleteMenuItem} sx={{ color: "red" }}>
          Supprimer
        </MenuItem>
      </Menu>
    </>
  );
};

export default MoreItemVerticalMenu;
