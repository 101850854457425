import { Box, Grid, Typography } from "@mui/material";
import { SubmitHandler } from "react-hook-form";
import { CustomForm } from "../../../../../../common/containers/Form/CustomForm";
import { useAppDispatch } from "../../../../../../common/store/redux";
import UpdateMenuItemNumberField from "../../../item/container/Forms/UpdateMenuItemNumberField";
import UpdateMenuItemTextField from "../../../item/container/Forms/UpdateMenuItemTextField";
import { supplementActions } from "../../slices/supplementSlice";
import { TAddSupplementFormAttributes } from "./types/TAddSupplementFormAttributes";
import { TAddSupplementsFormProps } from "./types/TAddSupplementsFormProps";

export default function AddSupplementsForm(props: TAddSupplementsFormProps) {
  const dispatch = useAppDispatch();

  const handleAddSupplement: SubmitHandler<TAddSupplementFormAttributes> = (
    data
  ) => {
    dispatch(
      supplementActions.addSupplementToList({
        name: data.supplementName,
        maxQuantity: data.supplementMaxQuantity,
        price: data.supplementPrice,
        itemId: props.itemId,
      })
    );
    props.setHasSupplementsChanged(true);
    return true;
  };

  return (
    <CustomForm
      defaultButtonText="Ajouter"
      onSubmit={handleAddSupplement}
      name="add-supplements-form"
      onSuccess={() => {}}
      useDefaultErrorDisplay={false}
      buttonStyle={{ width: "25%" }}
    >
      <Grid container direction="row" justifyContent={"center"}>
        <Grid item xs={12} md={12} lg={12} xl={12} mb={2}>
          <Grid container justifyContent={"center"} alignItems="center">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                "&::before, &::after": {
                  content: '""',
                  flex: 1,
                  borderBottom: "1px solid grey",
                },
                "&::before": {
                  marginRight: "0.5em",
                },
                "&::after": {
                  marginLeft: "0.5em",
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: `600`,
                  fontSize: "18px",
                }}
              >
                Garnitures
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <UpdateMenuItemTextField
          name="supplementName"
          label="Nom Garniture"
          validation={{
            required: " ",
          }}
          isBold={false}
          height="34px"
          width="130px"
        />

        <UpdateMenuItemNumberField
          name="supplementMaxQuantity"
          label="Qty/Max"
          validation={{
            required: " ",
          }}
          width="100px"
          height="36px"
        />

        <UpdateMenuItemNumberField
          name="supplementPrice"
          label="Prix/Unité"
          validation={{
            required: " ",
          }}
          currency="€"
          width="100px"
          height="36px"
        />
      </Grid>
    </CustomForm>
  );
}
