import InfoIcon from "@mui/icons-material/Info";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { getAccountBalance } from "../../../../services/fundsManagementService";
import {
  getBankAccountList,
  getLoginLink,
  getUpdateStripeAccoutLink,
} from "../../../../services/stripeAccountService";
import { fundsManagementActions } from "../../slices/fundsManagementSlice";
import { BankAccountList } from "../BankAccountList/BankAccountList";
import TransferToBankModal from "../Modals/TransferToBankModal";
import { TransferList } from "../TransferList/TransferList";

export default function BankTransferPage() {
  const dispatch = useAppDispatch();
  const bankAccountList = useAppSelector(
    (state) => state.stripeAccount.bankAccountList
  );
  const stripeBalance = useAppSelector(
    (state) => state.fundsManagement.stripeBalance
  );
  const updateStripeAccountLink = useAppSelector(
    (state) => state.stripeAccount.updateStripeAccountLink
  );

  useEffect(() => {
    dispatch(getAccountBalance());
    dispatch(getLoginLink());
    dispatch(getBankAccountList());
  }, []);

  const [availableBalance, setAvailableBalance] = useState<number>(0);
  const [pendingBalance, setPendingBalance] = useState<number>(0);

  useEffect(() => {
    let totalAvailableBalance = 0;
    let totalPendingBalance = 0;
    if (stripeBalance) {
      stripeBalance.available.reduce((previousBalance, stripeBalance) => {
        return (totalAvailableBalance = previousBalance + stripeBalance.amount);
      }, 0);
      stripeBalance.pending.reduce((previousBalance, stripeBalance) => {
        return (totalPendingBalance = previousBalance + stripeBalance.amount);
      }, 0);
    }
    setAvailableBalance(totalAvailableBalance / 100);
    setPendingBalance(totalPendingBalance / 100);
  }, [stripeBalance]);

  const handleManageBankAccount = () => {
    dispatch(getUpdateStripeAccoutLink());
  };
  const handleOpenTransferToBankModal = () => {
    dispatch(fundsManagementActions.setIsTransferToBankModalOpen(true));
  };

  return (
    <div>
      <TransferToBankModal
        bankAccountList={bankAccountList}
        stripeBalance={stripeBalance}
      />
      <Grid container mt={5}>
        <Grid item xs={12} md={12} xl={12} lg={12}>
          <Typography>Solde total disponible : {availableBalance} €</Typography>
        </Grid>
        <Grid item xs={12} md={12} xl={12} lg={12}>
          <Typography>
            Solde total en attente : {pendingBalance} €
            <span>
              <Tooltip
                title={
                  "Solde en attente de validation par stripe. Peut prendre jusqu'à 7 jours pour apparaître dans votre solde disponible"
                }
              >
                <InfoIcon
                  sx={{
                    fontSize: "1rem",
                    verticalAlign: "middle",
                    marginLeft: "5px",
                  }}
                />
              </Tooltip>
            </span>
          </Typography>
        </Grid>
        <Grid item mt={1}>
          <Button onClick={handleOpenTransferToBankModal}>
            Transférer le solde
          </Button>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          className="grid-center"
          mt={4}
          container
        >
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="h6">Liste des comptes bancaires</Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <BankAccountList bankAccountList={bankAccountList} />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          className="grid-center"
          mt={4}
          container
        >
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="h6">Gérer les comptes bancaires</Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Button onClick={handleManageBankAccount}>
              Gérer les comptes bancaires
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          className="grid-center"
          mt={4}
          container
        >
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="h6">Liste des transferts</Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TransferList />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
