import { Button, Grid, Typography } from "@mui/material";
import CustomPopup from "../../../../../../common/containers/Popup/CustomPopup";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../common/store/redux";
import { cartActions } from "../../../../slices/cartSlice";

export default function DeleteCartItemPopup() {
  const selectedItem = useAppSelector((state) => state.cart.selectedItem);
  const openDeletePopup = useAppSelector((state) => state.cart.openDeletePopup);
  const dispatch = useAppDispatch();
  const handleDeleteItem = () => {
    console.log(selectedItem);
    dispatch(cartActions.deleteCurrentCartItem(selectedItem.cartIndexes));
    dispatch(cartActions.setOpenDeletePopup(false));
  };
  const handleClosePopup = () => {
    dispatch(cartActions.setOpenDeletePopup(false));
  };
  return (
    <CustomPopup open={openDeletePopup} title="" onClose={handleClosePopup}>
      <Grid container padding={1}>
        <Grid mb={2} className="grid-center" xs={12}>
          <Typography className="listItem-heading-medium-primary">
            Etes-vous sûr de vouloir supprimer cet article ?
          </Typography>
        </Grid>
        <Grid container xs={12} className="grid-center">
          <Grid xs={4}>
            <Button className="popup-button-blue" onClick={handleDeleteItem}>
              Oui
            </Button>
          </Grid>
          <Grid xs={1}></Grid>
          <Grid xs={4}>
            <Button className="popup-button-blue" onClick={handleClosePopup}>
              Non
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </CustomPopup>
  );
}
