import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TMenuSliceState } from "./types/TMenuSliceState";
import { IMenuEntity } from "../../../../../common/Entities/IMenuEntity";
import { IMenuCategoryEntity } from "../../../../../common/Entities/IMenuCategoryEntity";
import { IMenuSupplementEntity } from "../../../../../common/Entities/IMenuSupplementEntity";
import { IMenuItemEntity } from "../../../../../common/Entities/IMenuItemEntity";
import { IMenuItemImageEntity } from "../../../../../common/Entities/IMenuItemImageEntity";

// Utility function to serialize dates
const serializeDate = (value: any): string | null => {
  if (value instanceof Date) {
    return value.toISOString();
  }
  return value ? String(value) : null;
};

const initialState: TMenuSliceState = {
  selectedMenu: null,
  isCreateMenuModalOpen: false,
  menuList: [],
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setSelectedMenu: (state, action: PayloadAction<IMenuEntity>) => {
      const menu = action.payload;
      state.selectedMenu = {
        ...menu,
        activeFrom: serializeDate(menu.activeFrom),
        activeTo: serializeDate(menu.activeTo),
      };
    },
    setIsCreateMenuModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isCreateMenuModalOpen = action.payload;
    },
    setMenuList: (state, action: PayloadAction<IMenuEntity[]>) => {
      console.log("action.payload", action.payload);
      state.menuList = action.payload.map((menu) => ({
        ...menu,
        activeFrom: serializeDate(menu.activeFrom),
        activeTo: serializeDate(menu.activeTo),
      }));
      console.log("New state:", state.menuList);
    },
    addMenu: (state, action: PayloadAction<IMenuEntity>) => {
      const menu = action.payload;
      state.menuList = [
        {
          ...menu,
          activeFrom: serializeDate(menu.activeFrom),
          activeTo: serializeDate(menu.activeTo),
        },
        ...state.menuList,
      ];
    },
    deleteMenu: (state, action: PayloadAction<number>) => {
      state.menuList = state.menuList.filter(
        (menu) => menu.id !== action.payload
      );
    },
    updateMenu: (state, action: PayloadAction<IMenuEntity>) => {
      state.menuList = state.menuList.map((menu) =>
        menu.id === action.payload.id
          ? {
              ...menu,
              ...action.payload,
              activeFrom: serializeDate(action.payload.activeFrom),
              activeTo: serializeDate(action.payload.activeTo),
            }
          : menu
      );
    },
    updateMenuCategory: (
      state,
      action: PayloadAction<{ menuId: number; category: IMenuCategoryEntity }>
    ) => {
      state.menuList = state.menuList.map((menu) =>
        menu.id === action.payload.menuId
          ? {
              ...menu,
              categories: menu.categories.map((cat) =>
                cat.id === action.payload.category.id
                  ? { ...cat, ...action.payload.category }
                  : cat
              ),
            }
          : menu
      );
    },
    deleteMenuItem: (
      state,
      action: PayloadAction<{
        menuId: number;
        categoryId: number;
        itemId: number;
      }>
    ) => {
      state.menuList = state.menuList.map((menu) =>
        menu.id === action.payload.menuId
          ? {
              ...menu,
              categories: menu.categories.map((cat) =>
                cat.id === action.payload.categoryId
                  ? {
                      ...cat,
                      itemList: cat.itemList.filter(
                        (menuItem) => menuItem.id !== action.payload.itemId
                      ),
                    }
                  : cat
              ),
            }
          : menu
      );
    },

    createMenuItem: (
      state,
      action: PayloadAction<{
        menuId: number;
        categoryId: number;
        newItem: IMenuItemEntity;
      }>
    ) => {
      state.menuList = state.menuList.map((menu) =>
        menu.id === action.payload.menuId
          ? {
              ...menu,
              categories: menu.categories.map((cat) =>
                cat.id === action.payload.categoryId
                  ? {
                      ...cat,
                      itemList: [...cat.itemList, action.payload.newItem],
                    }
                  : cat
              ),
            }
          : menu
      );
    },
    updateMenuItem: (
      state,
      action: PayloadAction<{
        menuId: number;
        categoryId: number;
        item: IMenuItemEntity;
      }>
    ) => {
      state.menuList = state.menuList.map((menu) =>
        menu.id === action.payload.menuId
          ? {
              ...menu,
              categories: menu.categories.map((cat) =>
                cat.id === action.payload.categoryId
                  ? {
                      ...cat,
                      itemList: cat.itemList.map((menuItem) =>
                        menuItem.id === action.payload.item.id
                          ? { ...menuItem, ...action.payload.item }
                          : menuItem
                      ),
                    }
                  : cat
              ),
            }
          : menu
      );
    },
    updateMenuSupplementList: (
      state,
      action: PayloadAction<{
        menuId: number;
        categoryId: number;
        itemId: number;
        newSupplementList: IMenuSupplementEntity[];
      }>
    ) => {
      state.menuList = state.menuList.map((menu) =>
        menu.id === action.payload.menuId
          ? {
              ...menu,
              categories: menu.categories.map((cat) =>
                cat.id === action.payload.categoryId
                  ? {
                      ...cat,
                      itemList: cat.itemList.map((menuItem) =>
                        menuItem.id === action.payload.itemId
                          ? {
                              ...menuItem,
                              supplements: action.payload.newSupplementList,
                            }
                          : menuItem
                      ),
                    }
                  : cat
              ),
            }
          : menu
      );
    },

    updateMenuItemImage: (
      state,
      action: PayloadAction<{
        menuId: number;
        categoryId: number;
        itemId: number;
        image: IMenuItemImageEntity;
      }>
    ) => {
      state.menuList = state.menuList.map((menu) =>
        menu.id === action.payload.menuId
          ? {
              ...menu,
              categories: menu.categories.map((cat) =>
                cat.id === action.payload.categoryId
                  ? {
                      ...cat,
                      itemList: cat.itemList.map((menuItem) =>
                        menuItem.id === action.payload.itemId
                          ? {
                              ...menuItem,
                              menuItemImage:
                                menuItem.menuItemImage.id ===
                                action.payload.image.id
                                  ? {
                                      ...menuItem.menuItemImage,
                                      ...action.payload.image,
                                    }
                                  : menuItem.menuItemImage,
                            }
                          : menuItem
                      ),
                    }
                  : cat
              ),
            }
          : menu
      );
    },
  },
});

export const menuReducer = menuSlice.reducer;
export const menuActions = menuSlice.actions;
