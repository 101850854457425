import { createAsyncThunk } from "@reduxjs/toolkit";
import { MethodEnum } from "../../../common/modules/enums/MethodEnum";
import { stripeAccountActions } from "../modules/StripeAccount/slices/stripeAccountSlice";
import { apiRequest } from "../../../common/modules/temporary/api";
import { TOnCreateConnectAccountRequest } from "./types/Requests/TOnCreateConnectAccountRequest";
import { TOnCreateConnectAccountResponse } from "./types/Responses/TOnCreateConnectAccountResponse";
import { TOnGenerateUpdateLinkResponse } from "./types/Responses/TOnGenerateUpdateLinkResponse";
import { TOnGetConnectAccountResponse } from "./types/Responses/TOnGetConnectAccountResponse";
import { appActions } from "../../../common/store/appSlice";
import { TOnListBankAccountsResponse } from "./types/Responses/TOnListBankAccountsResponse";
import { TOnCreateBankAccountRequest } from "./types/Requests/TOnCreateBankAccountRequest";
import { TOnCreateBankAccountResponse } from "./types/Responses/TOnCreateBankAccountResponse";
import { TOnDeleteBankAccountRequest } from "./types/Requests/TOnDeleteBankAccountRequest";
import { TOnDeleteBankAccountResponse } from "./types/Responses/TOnDeleteBankAccountResponse";
import { customFormActions } from "../../../common/store/customFormSlice";
import { TOnGetLoginLinkResponse } from "./types/Responses/TOnGetLoginLinkResponse";

export const getStripeAccount = createAsyncThunk(
  "connect-account/find",
  async (_, { dispatch }) => {
    const response = await apiRequest<TOnGetConnectAccountResponse>(
      dispatch,
      "/api/connect-account/find",
      MethodEnum.GET,
      null
    );
    if (response.success) {
      console.log("stripe account", response.data.account);
      dispatch(stripeAccountActions.setStripeAccount(response.data.account));
      dispatch(stripeAccountActions.setIsStripeAccount(true));
    }
  }
);
export const getLoginLink = createAsyncThunk(
  "connect-account/get-loginlink",
  async (_, { dispatch }) => {
    const response = await apiRequest<TOnGetLoginLinkResponse>(
      dispatch,
      "/api/connect-account/get-loginlink",
      MethodEnum.GET,
      null
    );
    if (response.success) {
      console.log("login link", response.data.loginLink);
      dispatch(stripeAccountActions.setLoginLink(response.data.loginLink));
    }
  }
);

export const getUpdateStripeAccoutLink = createAsyncThunk(
  "connect-account/update",
  async (_, { dispatch }) => {
    const response = await apiRequest<TOnGenerateUpdateLinkResponse>(
      dispatch,
      "/api/connect-account/update",
      MethodEnum.GET,
      null
    );
    if (response.success) {
      dispatch(
        stripeAccountActions.setUpdateStripeAccountLink(
          response.data.accountLink.url
        )
      );
      window.location.href = response.data.accountLink.url;
    }
  }
);

export const createConnectAccount = createAsyncThunk(
  "connect-account/create",
  async (requestData: TOnCreateConnectAccountRequest, { dispatch }) => {
    const response = await apiRequest<TOnCreateConnectAccountResponse>(
      dispatch,
      "/api/connect-account/create",
      MethodEnum.POST,
      requestData
    );
    if (response.success) {
      dispatch(
        appActions.setSuccessMessage(
          "Vous allez être redirigé vers la page d'inscription au compte Stripe connecté..."
        )
      );
      window.location.href = response.data.accountLink.url;
      dispatch(getStripeAccount());
      dispatch(stripeAccountActions.openSuccessModal(true));
    } else {
      dispatch(stripeAccountActions.openErrorModal(true));
    }
  }
);

export const getBankAccountList = createAsyncThunk(
  "connect-account/bank-account/list",
  async (_, { dispatch }) => {
    const response = await apiRequest<TOnListBankAccountsResponse>(
      dispatch,
      "/api/connect-account/bank-account/list",
      MethodEnum.GET
    );
    if (response.success) {
      console.log("bank account list", response.data);
      dispatch(stripeAccountActions.setBankAccountList(response.data));
    }
  }
);

export const createBankAccount = createAsyncThunk(
  "connect-account/bank-account/create",
  async (requestData: TOnCreateBankAccountRequest, { dispatch }) => {
    const response = await apiRequest<TOnCreateBankAccountResponse>(
      dispatch,
      "/api/connect-account/bank-account/create",
      MethodEnum.POST,
      requestData
    );
    if (response.success) {
      dispatch(
        appActions.setSuccessMessage(
          "Votre compte bancaire a été créé avec succès !"
        )
      );
      dispatch(customFormActions.setFormSuccess("bank-account-form"));
      dispatch(stripeAccountActions.setOpenCreateBankAccountModal(false));
      dispatch(getBankAccountList());
    } else {
      dispatch(
        appActions.setErrorMessage("Échec de la création du compte bancaire.")
      );
      dispatch(
        customFormActions.setFormError({
          name: "bank-account-form",
          message: "Échec de la création du compte bancaire.",
        })
      );
    }
  }
);

export const deleteBankAccount = createAsyncThunk(
  "connect-account/bank-account/delete",
  async (requestData: TOnDeleteBankAccountRequest, { dispatch }) => {
    const response = await apiRequest<TOnDeleteBankAccountResponse>(
      dispatch,
      "/api/connect-account/bank-account/delete",
      MethodEnum.POST,
      requestData
    );
    if (response.success) {
      dispatch(
        appActions.setSuccessMessage(
          "Votre compte bancaire a été supprimé avec succès !"
        )
      );
      dispatch(getBankAccountList());
    } else {
      dispatch(
        appActions.setErrorMessage(
          "Échec de la suppression du compte bancaire."
        )
      );
    }
  }
);
