import React, { useState, useRef } from "react";
import {
  PanoramaOutlined,
  DeleteOutlineOutlined,
  EditOutlined,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useAppDispatch } from "../../../../../../common/store/redux";
import { TPersonalizeItemImageUploadProps } from "./types/TPersonalizeItemImageUploadProps";

export default function PersonalizeItemImageUpload(
  props: TPersonalizeItemImageUploadProps
) {
  const [hovered, setHovered] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleHoverEnter = () => {
    setHovered(true);
  };

  const handleHoverLeave = () => {
    setHovered(false);
  };

  const handleDelete = () => {
    props.setHasImageChanged(true);
    setPreviewImage(null);
    props.setUploadedImage(null);
    props.setHasImageChanged(true);
  };

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        props.setHasImageChanged(true);
        const reader = new FileReader();
        reader.onload = (e) => {
          setPreviewImage(e.target?.result as string);
        };
        reader.readAsDataURL(file);
        props.setUploadedImage(file);
        props.setHasImageChanged(true);
      } else {
        alert("Please upload an image file");
        event.target.value = "";
      }
    }
  };

  const handleChangeImage = () => {
    fileInputRef.current?.click();
  };

  return (
    <div
      onMouseEnter={handleHoverEnter}
      onMouseLeave={handleHoverLeave}
      style={{
        maxWidth: "585px",
        maxHeight: "585px",
        height: "100%",
        width: "100%",
        minWidth: "585px",
        minHeight: "585px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        backgroundColor: hovered ? "#f0f0f0" : "#ffffff",
        border: hovered ? "1px dashed #ccc" : "1px solid #ccc",
        cursor:
          !previewImage && !props.selectedMenuItem.menuItemImage.image.url
            ? "pointer"
            : "default",
      }}
    >
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleUpload}
      />
      {!previewImage && !props.selectedMenuItem.menuItemImage.image.url ? (
        <label
          htmlFor="file-input"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
          onClick={handleChangeImage}
        >
          <PanoramaOutlined
            style={{
              width: "50%",
              height: "50%",
              color: hovered ? "rgba(0, 0, 0, 0.6)" : "rgba(0, 0, 0, 0.3)",
            }}
          />
        </label>
      ) : (
        <>
          <img
            src={
              previewImage ||
              props.selectedMenuItem.menuItemImage.image.url ||
              ""
            }
            alt=""
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
          {hovered && (
            <>
              <IconButton
                onClick={handleDelete}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  color: "#000",
                }}
              >
                <DeleteOutlineOutlined fontSize="small" />
              </IconButton>
              <IconButton
                onClick={handleChangeImage}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "50px",
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  color: "#000",
                }}
              >
                <EditOutlined fontSize="small" />
              </IconButton>
            </>
          )}
        </>
      )}
    </div>
  );
}
