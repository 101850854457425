import { Box, Grid, List } from "@mui/material";
import React from "react";
import GradientDivider from "../../../../common/containers/GradientDivider/GradientDivider";
import { useAppSelector } from "../../../../common/store/redux";
import SelectCartItemsButton from "../../modules/Payment/containers/SelectAllCartButton/SelectAllCartButton";
import CartRecapHeader from "../Header/CartRecapHeader";
import TotalPrice from "../TotalPrice/TotalPrice";
import CartItemNoCategory from "./CartItemNoCategory";
import CartItemWithCategory from "./CartItemWithCategory";
import { TOrderRecapBoxProps } from "./types/TOrderRecapBoxProps";
import QuantityIcons from "./QuantityIcons";

const CartRecapBox = (props: TOrderRecapBoxProps) => {
  const totalPrice = useAppSelector((state) => state.cart.totalPrice);
  const numberOfItems = useAppSelector((state) => state.cart.numberOfItems);
  let lastCategoryIndex = props.cart.categories.length - 1;

  const shouldRenderDivider = (
    categoryIndex: number,
    itemIndex: number,
    lastItemIndex: number
  ) => {
    return !(
      props.iconType !== "checkbox" &&
      categoryIndex === lastCategoryIndex &&
      lastItemIndex === itemIndex
    );
  };

  return props.cart ? (
    <>
      <Grid
        item
        container
        xs={12}
        sx={{
          border: 1,
          borderColor: "grey.300",
          borderRadius: 4,
          marginTop: 2,
          boxShadow: 1,
          paddingBottom: "20px",
        }}
      >
        <Grid item padding={2} xs={12}>
          {props.iconType === "checkbox" ? (
            <SelectCartItemsButton cart={props.cart} />
          ) : (
            <CartRecapHeader numberOfItems={numberOfItems} />
          )}
        </Grid>
        {props.showTotalPrice && (
          <Grid item xs={12} mb={2}>
            <TotalPrice
              variant={props.iconType === "checkbox" ? "selected" : "normal"}
            />
            <GradientDivider />
          </Grid>
        )}

        <Grid item xs={12}>
          {props.iconType === "checkbox" ? (
            <>
              {props.cart.categories.map(
                (cartCategories, categoryIndex) =>
                  cartCategories.items.length !== 1 && (
                    <Grid key={categoryIndex}>
                      <CartItemWithCategory
                        cartCategory={cartCategories}
                        categoryIndex={categoryIndex}
                        cart={props.cart}
                        iconType={props.iconType}
                        showQuantityBox={props.showQuantityBox}
                        lastCategoryIndex={lastCategoryIndex}
                      />
                    </Grid>
                  )
              )}
              {props.cart.categories.map(
                (cartCategories, categoryIndex) =>
                  cartCategories.items.length === 1 && (
                    <Grid key={categoryIndex} mb={4} mt={2}>
                      <CartItemNoCategory
                        categoryIndex={categoryIndex}
                        itemIndex={0}
                        item={cartCategories.items[0]}
                        iconType={props.iconType}
                        showQuantityBox={props.showQuantityBox}
                        lastCategoryIndex={lastCategoryIndex}
                      />
                    </Grid>
                  )
              )}
            </>
          ) : (
            <>
              {props.cart.categories.map((cartCategories, categoryIndex) => {
                let lastItemIndex = cartCategories.items.length - 1;
                return cartCategories.items.map((item, itemIndex) => (
                  <React.Fragment key={itemIndex}>
                    <CartItemNoCategory
                      categoryIndex={categoryIndex}
                      itemIndex={itemIndex}
                      item={item}
                      iconType={props.iconType}
                      showQuantityBox={props.showQuantityBox}
                    />
                    {shouldRenderDivider(
                      categoryIndex,
                      itemIndex,
                      lastItemIndex
                    ) && (
                      <Grid
                        item
                        xs={12}
                        paddingLeft={5}
                        paddingRight={5}
                        mb={2}
                        mt={2}
                      >
                        <GradientDivider />
                      </Grid>
                    )}
                  </React.Fragment>
                ));
              })}
            </>
          )}
        </Grid>
      </Grid>
    </>
  ) : (
    <>Panier vide</>
  );
};

export default CartRecapBox;
