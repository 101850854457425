import React from "react";
import { Button } from "@mui/material";
import { TCustomButtonProps } from "./types/TCustomButtonProps";

export default function CustomButton(props: TCustomButtonProps) {
  return (
    <Button
      className={
        props.variant === "primary" ? `button-primary` : `button-secondary`
      }
      onClick={props.handleOnClick}
    >
      {props.buttonText}
    </Button>
  );
}
