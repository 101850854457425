import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IMenuCategoryEntity } from "../../../../../../common/Entities/IMenuCategoryEntity";
import { IMenuEntity } from "../../../../../../common/Entities/IMenuEntity";
import { IMenuItemEntity } from "../../../../../../common/Entities/IMenuItemEntity";
import { useAppDispatch } from "../../../../../../common/store/redux";
import SelectedMenuItemForm from "../Forms/SelectedMenuItemForm";
import PersonalizeItemImageUpload from "../PersonalizeItemImageUpload.tsx/PersonalizeItemImageUpload";
import { TSelectedMenuItemPageProps } from "./types/TSelectedMenuItemPageProps";
import LeavePageConfirmationModal from "../../../../../../common/containers/Popup/LeavePageConfirmationModal";

export default function SelectedMenuItemPage(
  props: TSelectedMenuItemPageProps
) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { menuId } = useParams();
  const { categoryId } = useParams();
  const { itemId } = useParams();

  const [selectedMenu, setSelectedMenu] = useState<IMenuEntity | null>(null);
  const [selectedCategory, setSelectedCategory] =
    useState<IMenuCategoryEntity | null>(null);
  const [selectedItem, setSelectedItem] = useState<IMenuItemEntity | null>(
    null
  );
  const [hasImageChanged, setHasImageChanged] = useState<boolean>(false);
  const [hasItemChanged, setHasItemChanged] = useState<boolean>(false);
  const [hasSupplementChanged, setHasSupplementChanged] =
    useState<boolean>(false);
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);

  useEffect(() => {
    if (!menuId) {
      console.log("pas de menuId");
      navigate("/dashboard/menus");
    } else {
      const foundMenu = props.menuList.find(
        (menu) => menu.id === Number(menuId)
      );
      if (!foundMenu) {
        console.log("pas de menu trouvé");
        navigate("/dashboard/menus");
      } else {
        setSelectedMenu(foundMenu);
        console.log("categoryId", categoryId);
        const foundCategory = foundMenu.categories.find(
          (category) => category.id === Number(categoryId)
        );
        if (!foundCategory) {
          console.log("pas de category trouvé");
          navigate(`/dashboard/menus/${menuId}`);
        } else {
          setSelectedCategory(foundCategory);
          const foundItem = foundCategory.itemList.find(
            (item) => item.id === Number(itemId)
          );
          if (!foundItem) {
            console.log("pas d'item trouvé");
            navigate(`/dashboard/menus/${menuId}`);
          } else {
            setSelectedItem(foundItem);
          }
        }
      }
    }
  }, [menuId, categoryId, itemId, JSON.stringify(props.menuList)]);

  useEffect(() => {
    if (selectedMenu && categoryId && itemId) {
      const foundCategory = selectedMenu.categories.find(
        (category) => category.id === Number(categoryId)
      );
      if (foundCategory) {
        setSelectedCategory(foundCategory);
        const foundItem = foundCategory.itemList.find(
          (item) => item.id === Number(itemId)
        );
        if (foundItem) {
          console.log("item trouvé", selectedMenu);
          setSelectedItem(foundItem);
        }
      }
    }
  }, [selectedMenu, categoryId]);

  let hasUnsavedChanges =
    hasImageChanged || hasItemChanged || hasSupplementChanged;
  console.log("hasUnsavedChanges", hasUnsavedChanges);

  return (
    selectedMenu &&
    selectedCategory &&
    selectedItem && (
      <Grid container>
        <LeavePageConfirmationModal hasUnsavedChanges={hasUnsavedChanges} />
        <Grid item>
          <Box sx={{ display: "flex", flexWrap: "nowrap", width: "100%" }}>
            <Box sx={{ minWidth: "200px", maxWidth: "585px", width: "100%" }}>
              <PersonalizeItemImageUpload
                selectedMenuItem={selectedItem}
                hasImageChanged={hasImageChanged}
                setHasImageChanged={setHasImageChanged}
                setUploadedImage={setUploadedImage}
              />
            </Box>
            <Box
              sx={{
                width: `calc(100% - 585})`,
                flexShrink: 0,
                overflow: "auto",
                paddingLeft: 8,
              }}
            >
              <SelectedMenuItemForm
                selectedCategory={selectedCategory}
                selectedItem={selectedItem}
                selectedMenu={selectedMenu}
                hasImageChanged={hasImageChanged}
                setHasImageChanged={setHasImageChanged}
                hasItemChanged={hasItemChanged}
                setHasItemChanged={setHasItemChanged}
                hasSupplementChanged={hasSupplementChanged}
                setHasSupplementChanged={setHasSupplementChanged}
                uploadedImage={uploadedImage}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    )
  );
}
