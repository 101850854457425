import { Typography } from "@mui/material";
import BackToMenuButton from "../BackToMenuButton/BackToMenuButton";
import { TCartHeaderProps } from "./types/TCartHeaderProps";

export default function CartHeader(props: TCartHeaderProps) {
  return (
    <>
      <BackToMenuButton userRef={props.userRef} table={props.table} />
      <Typography className="heading-large-primary" gutterBottom>
        {props.title}
      </Typography>
      <Typography className="heading-large-secondary" gutterBottom>
        {props.subtitle}
      </Typography>
    </>
  );
}
