import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TBackToMenuButtonProps } from "./types/TBackToMenuButtonProps";

export default function BackToMenuButton(props: TBackToMenuButtonProps) {
  const navigate = useNavigate();
  const handleBackToMenu = () => {
    navigate(
      `/customerMenu/empty?userRef=${props.userRef}&table=${props.table}`
    );
  };
  return (
    <Grid alignItems={"end"} display={"flex"} justifyContent={"end"} mb={2}>
      <Button className="menu-previous-button" onClick={handleBackToMenu}>
        Revenir au Menu
      </Button>
    </Grid>
  );
}
