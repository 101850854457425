import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { SubmitHandler } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { CustomForm } from "../../../../common/containers/Form/CustomForm";
import { customFormActions } from "../../../../common/store/customFormSlice";
import { useAppDispatch, useAppSelector } from "../../../../common/store/redux";
import { signIn } from "../../services/authService";
import CustomCheckboxField from "./CustomCheckboxField";
import CustomEmailField from "./CustomEmailField";
import CustomPasswordField from "./CustomPasswordField";
import { TSignInFormAttributes } from "./types/TSignInFormAttributes";

const SignInForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const submitHandler: SubmitHandler<TSignInFormAttributes> = (data) => {
    dispatch(signIn(data));
  };
  const successHandler = () => {
    navigate("/dashboard");
  };

  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(customFormActions.setFormSuccess("sign-in-form"));
    }
  }, [!!isAuthenticated]);

  return (
    <CustomForm
      onSubmit={submitHandler}
      defaultButtonText="Connexion"
      onSuccess={() => successHandler()}
      name="sign-in-form"
    >
      <Grid item mb={4}>
        <CustomEmailField name="email" label="Email" />
      </Grid>
      <Grid item mb={4}>
        <CustomPasswordField
          name="password"
          label="Mot de passe"
          loginDetails={true}
        />
      </Grid>
    </CustomForm>
  );
};

export default SignInForm;
