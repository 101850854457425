import React from "react";
import { Grid, InputAdornment } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { StyledNumberField } from "./StyledNumberField";
import { TUpdateMenuItemNumberFieldProps } from "./types/TUpdateMenuItemNumberFieldProps";

const UpdateMenuItemNumberField: React.FC<TUpdateMenuItemNumberFieldProps> = (
  props
) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[props.name]?.message;

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (value: string) => void
  ) => {
    const value = e.target.value;
    // Allow empty input, digits, and one dot with up to two decimal places
    if (value === "" || /^\d*\.?\d{0,2}$/.test(value)) {
      onChange(value);
    }
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (value: string) => void
  ) => {
    let value = e.target.value.trim();

    // Remove trailing dot
    if (value.endsWith(".")) {
      value = value.slice(0, -1);
    }

    onChange(value);
  };

  return (
    <Grid item m={2}>
      <Controller
        name={props.name}
        control={control}
        rules={props.validation}
        defaultValue={props.defaultValue}
        render={({ field }) => (
          <StyledNumberField
            {...field}
            onChange={(e) => handleChange(e, field.onChange)}
            onBlur={(e) => {
              field.onBlur();
              handleBlur(e, field.onChange);
            }}
            label={props.label}
            variant="outlined"
            type="text"
            error={!!errorMessage}
            width={props.width}
            height={props.height}
            InputProps={{
              startAdornment: props.currency ? (
                <InputAdornment position="start">
                  {props.currency}
                </InputAdornment>
              ) : null,
              inputMode: "decimal",
            }}
            value={field.value ?? ""}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
    </Grid>
  );
};

export default UpdateMenuItemNumberField;
