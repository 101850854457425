import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { initiatePayment } from "../../../modules/Payment/services/paymentServices";
import { useAppDispatch, useAppSelector } from "../../store/redux";
import { CustomForm } from "../Form/CustomForm";
import { TStripeCheckoutFormProps } from "./types/TStripeCheckoutFormProps";
import { ICartItemEntity } from "../../Entities/ICartItemEntity";
import { ICartEntity } from "../../Entities/ICartEntity";

export default function StripeCheckoutForm(props: TStripeCheckoutFormProps) {
  const dispatch = useAppDispatch();
  const paymentCart = useAppSelector((state) => state.cart.paymentCart);
  const clientSecret = useAppSelector((state) => state.payment.paymentRef);
  const stripe = useStripe();
  const elements = useElements();

  const getCartItemsFromPaymentCart = (
    paymentCart: ICartEntity
  ): ICartItemEntity[] => {
    return paymentCart.categories.flatMap((category) => {
      return category.items.map((item) => item);
    });
  };
  const handleOnSubmit = async () => {
    if (stripe && elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError) {
        console.log("error stripe", submitError);
        return;
      }
      dispatch(
        initiatePayment({
          price: props.totalPrice,
          cartItems: getCartItemsFromPaymentCart(paymentCart),
          userRef: props.userRef,
        })
      );
    }
  };
  const handleOnSuccess = async () => {
    // Confirm the PaymentIntent using the details collected by the Payment Element
    if (stripe && elements) {
      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: "https://example.com/order/123/complete",
        },
      });

      if (error) {
        console.log("error stripe", error);
      }
    }
  };
  return (
    <CustomForm
      defaultButtonText="Payer"
      onSubmit={handleOnSubmit}
      onSuccess={handleOnSuccess}
      submitDisabled={!stripe}
      name="stripe-checkout-form"
    >
      <PaymentElement />
    </CustomForm>
  );
}
