import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../../../../../common/containers/styles/customFonts.css";
import decreaseDownArrow from "../../../../../../common/images/decrease_down_arrow.png";
import increaseUpArrow from "../../../../../../common/images/increase_up_arrow.png";
import { TStatsBoxProps } from "./type/TStatsBoxProps";

function formatStatValue(value: number): string {
  if (value >= 1000000) {
    return (value / 1000000).toFixed(1) + "M";
  } else if (value >= 1000) {
    return (value / 1000).toFixed(1) + "K";
  }
  return value.toString();
}

const showGrowthText = (value: "MONTH" | "WEEK"): string => {
  switch (value) {
    case "MONTH":
      return "ce mois-ci";
    case "WEEK":
      return "cette semaine-ci";
    default:
      return "ce jour-ci";
  }
};

export default function StatsBox(props: TStatsBoxProps) {
  const navigate = useNavigate();
  const handleGoToTransferPage = () => {
    navigate("bank/transfer");
  };
  const formattedStatValue = formatStatValue(props.statValue);

  useEffect(() => {
    console.log("growthPercentage", props.growthPercentage);
  }, [props.growthPercentage]);

  return props.statValue >= 0 ? (
    <Box
      sx={{
        width: "100%",
        maxWidth: props.width || "350px",
        minWidth: "100px",
        m: 1,
        backgroundColor: "white",
        boxShadow: "0px 1px 1.5px 0px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
      }}
    >
      <Grid
        container
        sx={{ height: "100%", minHeight: "135px", maxHeight: "135px" }}
      >
        {props.img && (
          <Grid item xs={12} sm="auto" className="grid-center" p={2}>
            <img
              src={props.img.url}
              style={{
                width: props.img.width || "84px",
                height: props.img.height || "84px",
                maxWidth: "100%",
                objectFit: "contain",
              }}
              alt={props.title}
            />
          </Grid>
        )}

        <Grid item xs={12} sm container direction="column" p={2}>
          <Typography
            className="font-poppins"
            sx={{ fontSize: "14px", color: "#ACACAC" }}
          >
            {props.title}
          </Typography>
          <Typography
            sx={{ fontSize: { xs: "24px", sm: "32px" }, fontWeight: "600" }}
            className="font-poppins"
          >
            {`${formattedStatValue} ${props.hideCurrency ? `` : `€`}`}
          </Typography>

          {props.growthPercentage !== undefined &&
            props.growthTimeframe !== undefined && (
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <img
                    src={
                      props.growthPercentage >= 0
                        ? increaseUpArrow
                        : decreaseDownArrow
                    }
                    style={{ width: "20px", height: "20px" }}
                    alt={props.growthPercentage >= 0 ? "Increase" : "Decrease"}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{ fontSize: "12px", fontWeight: "600" }}
                    className="font-poppins"
                  >
                    <span
                      style={{
                        color:
                          props.growthPercentage >= 0 ? "#00AC4F" : "#D0004B",
                        fontWeight: "bold",
                      }}
                    >
                      {props.growthPercentage === 0
                        ? 0
                        : props.growthPercentage.toFixed(2)}
                      {"% "}
                    </span>
                    {showGrowthText(props.growthTimeframe)}
                  </Typography>
                </Grid>
              </Grid>
            )}

          {props.transferButton && (
            <Grid container justifyContent="flex-end" mt={1}>
              <Button
                sx={{
                  backgroundColor: "#091E42",
                  width: "91px",
                  height: "24px",
                  fontSize: "10px",
                  color: "white",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#091E42",
                    opacity: 0.9,
                  },
                }}
                onClick={handleGoToTransferPage}
              >
                Transférer
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  ) : null;
}
