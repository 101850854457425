import { Container, Grid, Typography } from "@mui/material";
import GradientDivider from "../../../../../common/containers/GradientDivider/GradientDivider";
import StripeCheckoutForm from "../../../../../common/containers/Stripe/StripeCheckoutForm";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/store/redux";
import CartHeader from "../../../containers/Header/CartHeader";
import TotalPrice from "../../../containers/TotalPrice/TotalPrice";
import AddTipButton from "../AddTipsButton/AddTipButton";
import AddCustomTipModal from "../Popup/AddCustomTipModal";
import CartPaymentRecapBox from "../containers/CartRecapBox/CartPaymentRecapBox";
import CustomStripeElements from "../../../../../common/containers/Stripe/CustomStripeElements";
import { useEffect } from "react";
import { clearCartState } from "../../../../../utils/cart/cartLocalStorage";
import { cartActions } from "../../../slices/cartSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { findAvailable } from "../../../services/cartService";

const CartPaymentPage = () => {
  const dispatch = useAppDispatch();
  const cartState = useAppSelector((state) => state.cart.currentCart);
  console.log("cartState", cartState);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const userRef = urlParams.get("userRef") || "";
  const table = urlParams.get("table") || "";
  const tip = useAppSelector((state) => state.cart.tip);
  const totalPaymentPrice = useAppSelector(
    (state) => state.cart.totalPaymentPrice
  );
  const navigate = useNavigate();

  useEffect(() => {
    clearCartState();
    dispatch(cartActions.resetCart());
    dispatch(cartActions.initializePaymentCart());
  }, []);
  useEffect(() => {
    return () => {
      dispatch(cartActions.resetCart());
    };
  }, [dispatch, navigate]);
  useEffect(() => {
    cartState.categories.length === 0 &&
      dispatch(findAvailable({ userRef: userRef, table: table }));
  }, [JSON.stringify(cartState)]);

  return cartState && cartState?.categories.length > 0 ? (
    <>
      <AddCustomTipModal />
      <Container>
        <Grid container xs={12} mt={2}>
          <Grid item xs={12}>
            <CartHeader
              userRef={userRef}
              table={table}
              title="Régler l'addition"
              subtitle="Nous espérons que vous avez apprécié et merci pour votre commande."
            />
          </Grid>
          <Grid item xs={12}>
            <CartPaymentRecapBox cart={cartState} />
          </Grid>

          <Grid item container marginTop={3} marginBottom={3} padding={2}>
            <Grid item xs={12}>
              <Typography className="listItem-heading-medium-primary">
                Ajouter un pourboire
              </Typography>
            </Grid>
            <Grid item container xs={12} mt={1}>
              <AddTipButton tips={["1.00", "2.50", "5.00"]} />
            </Grid>
            <Grid item xs={12} md={8} sm={12} lg={8} mt={4}>
              {tip > 0 && <TotalPrice variant="tip" />}
            </Grid>
            <Grid item xs={12} md={8} sm={12} lg={8}>
              <GradientDivider />
              <TotalPrice variant="selected" />
            </Grid>
            {totalPaymentPrice > 0 && (
              <>
                <Grid item container xs={12} className="grid-center">
                  <Grid item xs={11}>
                    <CustomStripeElements
                      options={{
                        mode: "payment",
                        amount: parseFloat(
                          (totalPaymentPrice * 100).toFixed(2)
                        ),
                        currency: "eur",
                      }}
                      totalPrice={parseFloat(
                        (totalPaymentPrice * 100).toFixed(2)
                      )}
                      userRef={cartState.userRef}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  ) : (
    <>Votre panier est vide</>
  );
};

export default CartPaymentPage;
