import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IMenuEntity } from "../../../../../../common/Entities/IMenuEntity";
import { useAppDispatch } from "../../../../../../common/store/redux";
import { menuActions } from "../../slice/menuSlice";
import { TMenuBoxProps } from "./types/TMenuBoxProps";
import MoreVerticalMenu from "../MoreVerticalMenu/MoreVerticalMenu";
import MenuItemListPreview from "../MenuItemList/MenuItemListPreview";

export default function MenuBox(props: TMenuBoxProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const formatDateTime = (date: Date) => {
    if (!date) return "";
    return date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };
  const handleGoToMenu = (menu: IMenuEntity) => {
    dispatch(menuActions.setSelectedMenu(menu));
    navigate(`/dashboard/menus/${menu.id!}`);
  };

  const showMenuBoxTimeframeText = (
    activeFrom: Date | null,
    activeTo: Date | null
  ): string => {
    if (activeFrom && activeTo) {
      return `(${formatDateTime(activeFrom)} - ${formatDateTime(activeTo)})`;
    }
    return "(Toute la journée)";
  };

  const showMenuBoxTitle = (menu: IMenuEntity) => {
    {
      return (
        <>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "20px",
              "&::first-letter": {
                textTransform: "uppercase",
              },
            }}
          >
            {menu.name}{" "}
            <span style={{ fontWeight: "400", fontSize: "12px" }}>
              {showMenuBoxTimeframeText(menu.activeFrom, menu.activeTo)}
            </span>
          </Typography>
        </>
      );
    }
  };

  return props.menuList && props.menuList.length > 0 ? (
    <>
      {props.menuList.map((menu) => {
        return (
          <div>
            <Grid
              item
              mt={4}
              key={menu.id}
              ref={(el) => (props.menuRefs.current[menu.id!] = el)}
              md={12}
              lg={12}
              xl={12}
            >
              <Grid item container m={2} pr={2} key={menu.id}>
                <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                  {showMenuBoxTitle(menu)}
                </Grid>
                <Grid
                  item
                  container
                  justifyContent={"end"}
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  xl={2}
                >
                  <MoreVerticalMenu
                    selectedMenu={menu}
                    menuList={props.menuList}
                  />
                </Grid>
              </Grid>
              <div
                onClick={() => {
                  handleGoToMenu(menu);
                }}
                style={{ cursor: "pointer" }}
              >
                <Grid
                  container
                  direction={"row"}
                  sx={{
                    boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.25)`,
                    borderRadius: "6px",
                    "&:hover": {
                      boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.4)`,
                      backgroundColor: "rgba(0, 0, 0, 0.02)",
                    },
                  }}
                >
                  {menu.categories.length > 0 &&
                  menu.categories[0].itemList.length > 0 ? (
                    <>
                      <MenuItemListPreview
                        itemList={menu.categories[0].itemList}
                        selectedCategory={menu.categories[0]}
                        selectedMenu={menu}
                      />
                    </>
                  ) : (
                    <>
                      <Grid
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        m={4}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          Ajouter des articles
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </div>
            </Grid>
          </div>
        );
      })}
    </>
  ) : (
    <></>
  );
}
