import { Box, Typography } from "@mui/material";
import { useAppSelector } from "../../../../common/store/redux";
import { TTotalPriceProps } from "./types/TTotalPriceProps";

export default function TotalPrice(props: TTotalPriceProps) {
  const totalPrice = useAppSelector((state) => state.cart.totalPrice);
  const tip = useAppSelector((state) => state.cart.tip);
  const totalPaymentPrice = useAppSelector(
    (state) => state.cart.totalPaymentPrice
  );

  // Convert to number to ensure no leading zeros
  const totalSelectedPriceNumber = Number(totalPaymentPrice);
  const tipNumber = Number(tip);

  // Calculate the sum of totalSelectedPrice and tip
  const totalWithTipNumber = totalSelectedPriceNumber + tipNumber;

  // Format the totalWithTip with conditional decimals
  const totalWithTip =
    totalWithTipNumber % 1 === 0
      ? totalWithTipNumber
      : totalWithTipNumber.toFixed(2);

  return (
    <Box display="flex" justifyContent="space-between" padding={2}>
      <Typography className="heading-medium-primary">
        {props.variant === "tip" ? `Pourboire` : `Total`}
      </Typography>
      <Typography className="priceTag-normal-black">
        {props.variant === "normal" && `${totalPrice} €`}
        {props.variant === "selected" && `${totalWithTip} €`}
        <span style={{ color: "#60B777" }}>
          {props.variant === "tip" &&
            `${tip % 1 === 0 ? tip : Number(tip).toFixed(2)} €`}
        </span>
      </Typography>
    </Box>
  );
}
