import { Button, DialogContent, DialogTitle, Grid } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import { useEffect, useState } from "react";
import { TLeavePageConfirmationModalProps } from "./types/TLeavePageConfirmationModalProps";
import { useNavigationBlocker } from "../../hooks/useNavigationBlocker/useNavigationBlocker";

export default function LeavePageConfirmationModal(
  props: TLeavePageConfirmationModalProps
) {
  const [isOpen, setIsOpen] = useState(false);
  const { closeWindow, keepWindow, isLeavingPage } = useNavigationBlocker(
    props.hasUnsavedChanges
  );
  const isLeaving = isLeavingPage();

  useEffect(() => {
    if (props.hasUnsavedChanges && isLeaving) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
      closeWindow();
    }
  }, [isLeaving, props.hasUnsavedChanges]);

  const handleCloseModal = () => {
    setIsOpen(false);
    keepWindow();
  };

  return (
    <MuiDialog
      open={isOpen}
      onClose={handleCloseModal}
      maxWidth="sm"
      slotProps={{
        backdrop: {
          onClick: handleCloseModal,
        },
      }}
    >
      <DialogTitle>Attention !</DialogTitle>
      <DialogContent>
        Vous avez des modifications non enregistrées. Voulez-vous vraiment
        quitter la page ?
      </DialogContent>
      <Grid container className="grid-center" mb={2} spacing={2}>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
          <Button className="blue-round-button" onClick={closeWindow}>
            Oui
          </Button>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
          <Button className="blue-round-button" onClick={keepWindow}>
            Non
          </Button>
        </Grid>
      </Grid>
    </MuiDialog>
  );
}
