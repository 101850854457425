import { Typography } from "@mui/material";
import "../../../../common/containers/styles/customFonts.css";
import { ReactComponent as LogoutIcon } from "../../../../common/images/logout_icon.svg";
import { useAppDispatch } from "../../../../common/store/redux";
import { signOut } from "../../services/authService";
import { retrieveRefreshToken } from "../../../../utils/crypto";

export default function SignoutButton() {
  const dispatch = useAppDispatch();
  const handleOnSignout = () => {
    dispatch(signOut({ refreshToken: retrieveRefreshToken() }));
  };
  return (
    <>
      <Typography
        className="font-inter"
        sx={{ fontSize: "13px" }}
        pr={1}
        onClick={() => {
          handleOnSignout();
        }}
      >
        Déconnexion
      </Typography>
      <LogoutIcon />
    </>
  );
}
