import React from "react";
import { TUseMenuItemRepositoryProps } from "./types/TUseMenuItemRepositoryProps";
import { TUpdateMenuItemRequest } from "../service/type/Requests/TUpdateMenuItemRequest";
import { IMenuItemEntity } from "../../../../../common/Entities/IMenuItemEntity";
import { useAppDispatch } from "../../../../../common/store/redux";
import {
  createMenuItem,
  deleteMenuItem,
  updateMenuItem,
} from "../service/itemServices";
import { menuActions } from "../../menu/slice/menuSlice";
import { TCreateMenuItemRequest } from "../service/type/Requests/TCreateMenuItemRequest";
import { TDeleteMenuItemRequest } from "../service/type/Requests/TDeleteMenuItemRequest";

export default function useMenuItemRepository(
  props: TUseMenuItemRepositoryProps
) {
  const dispatch = useAppDispatch();

  async function onUpdateMenuItem(
    requestData: TUpdateMenuItemRequest
  ): Promise<IMenuItemEntity> {
    console.log("requestData", requestData);
    const resultAction = await dispatch(updateMenuItem(requestData));

    if (updateMenuItem.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    const updatedMenuItem = resultAction.payload;
    dispatch(
      menuActions.updateMenuItem({
        menuId: props.selectedMenu.id!,
        categoryId: props.selectedCategory.id!,
        item: updatedMenuItem,
      })
    );
    return updatedMenuItem;
  }
  async function onCreateMenuItem(
    requestData: TCreateMenuItemRequest
  ): Promise<IMenuItemEntity> {
    const resultAction = await dispatch(createMenuItem(requestData));

    if (createMenuItem.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    const createdMenuItem = resultAction.payload;
    dispatch(
      menuActions.createMenuItem({
        menuId: props.selectedMenu.id!,
        categoryId: props.selectedCategory.id!,
        newItem: createdMenuItem,
      })
    );
    return createdMenuItem;
  }
  async function onDeleteMenuItem(
    requestData: TDeleteMenuItemRequest
  ): Promise<boolean> {
    const resultAction = await dispatch(deleteMenuItem(requestData));

    if (createMenuItem.rejected.match(resultAction)) {
      throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
    dispatch(
      menuActions.deleteMenuItem({
        menuId: props.selectedMenu.id!,
        categoryId: props.selectedCategory.id!,
        itemId: requestData.menuItem.id!,
      })
    );
    return true;
  }
  return { onUpdateMenuItem, onCreateMenuItem, onDeleteMenuItem };
}
