import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loadCartState } from "../../../utils/cart/cartLocalStorage";
import { useAppDispatch, useAppSelector } from "../../store/redux";
import { TProtectedRouteProps } from "./types/TProtectedRouteProps";
import { findAvailable } from "../../../modules/Cart/services/cartService";

const MenuProtectedRoute = ({ children }: TProtectedRouteProps) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const userRef = urlParams.get("userRef") || "";
  const table = urlParams.get("table") || "";
  const cartStateInLocatStorage = loadCartState();
  const cartState = useAppSelector((state) => state.cart.currentCart);
  /*const paymentAvailable = useAppSelector(
    (state) => state.menuPreview.paymentAvailable
  );*/
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  /*useEffect(() => {
    if (userRef && table) {
      dispatch(menuPreviewActions.setUserRef(userRef));
      dispatch(menuPreviewActions.setTable(table));
      dispatch(findAvailable({ table: table, userRef: userRef }));
    }
    if (cartStateInLocatStorage?.currentCart) {
      navigate(`validation?userRef=${userRef}&table=${table}`);
    }
  }, []);*/

  /*useEffect(() => {
    if (userRef && table) {
      if (paymentAvailable === true && !cartStateInLocatStorage) {
        navigate(`payment?userRef=${userRef}&table=${table}`);
      }
      if (paymentAvailable === false && !cartStateInLocatStorage) {
        navigate(`empty?userRef=${userRef}&table=${table}`);
      }
    }
    if (userRef && !table) {
      if (!paymentAvailable) {
        navigate(`scan?userRef=${userRef}`);
      }
    }
  }, [paymentAvailable]);*/

  return (
    <>{/*{(paymentAvailable !== null || (userRef && !table)) && children}*/}</>
  );
};

export default MenuProtectedRoute;
